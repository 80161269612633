import React, { useEffect } from 'react';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addAnonymousUser, AuthState } from '../../store/Auth/AuthSlice';

interface Props {
  user: any;
  children: React.ReactElement;
}

const AnonymousSessionWrapper = ({ user, children }: Props) => {
  const authState = useAppSelector(AuthState);
  const dispatch = useAppDispatch();

  const signInAsAnonymousUser = async () => {
    const AnonymousUser = await signInAnonymously(auth)
      .then((res) => {
        console.log('Signed as Anonymous', res);
      })
      .catch((err) => {
        console.log(err);
      });

    return AnonymousUser;
  };

  useEffect(() => {
    if (!authState.isAnonymous && !authState.isAuthenticated) {
      signInAsAnonymousUser().then((res) => {
        dispatch(addAnonymousUser());
      });
    }
  }, [user]);

  return <>{children}</>;
};

export default AnonymousSessionWrapper;
