import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Container } from '@mui/system';
import styled from '@emotion/styled';
import LoginRightImg from '../../assets/login_right.svg';
import BicepsImg from '../../assets/fitness-biceps.svg';
import DumbbellImg from '../../assets/dumbbell.svg';
import SlimWaistImg from '../../assets/slim-waist.svg';

export const LoginRightContent = () => {
  return (
    <>
      <img src={LoginRightImg} />
      <RightMainText>Crushing your health and fitness goals starts here...</RightMainText>
      <RightSubText>
        Get fit, stay healthy, and live life on their terms without fitness being an obstacle.
      </RightSubText>
      <Container sx={{ display: 'flex', gap: '35px', justifyContent: 'center', marginTop: '20px' }}>
        <ImgBox>
          <img src={BicepsImg} style={{ height: '50px', width: '50px' }} />
          <ImgLabels>Personal Training</ImgLabels>
        </ImgBox>
        <Divider sx={{ border: '1px solid #E5E6EB;' }} />
        <ImgBox>
          <img src={DumbbellImg} style={{ height: '50px', width: '50px' }} />
          <ImgLabels>Workout Program</ImgLabels>
        </ImgBox>
        <Divider sx={{ border: '1px solid #E5E6EB;' }} />
        <ImgBox>
          <img src={SlimWaistImg} style={{ height: '50px', width: '50px' }} />
          <ImgLabels>Program & Diet</ImgLabels>
        </ImgBox>
      </Container>
    </>
  );
};

const RightMainText = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.8px;
  color: #12022f;
  width: 393px;
  text-align: center;
  margin-top: 33px;
  margin-bottom: 18px;
`;

const ImgBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightSubText = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.355556px;
  color: #594d6d;
  width: 380px;
  text-align: center;
`;

const ImgLabels = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #12022f;
  width: max-content;
`;
