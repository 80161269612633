import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Divider, Typography, TypographyProps } from '@mui/material';
import { colors } from '../../utils/constants/colors';
import DistanceIcon from '../../assets/distance_icon.svg';
import TooltipStarIcon from '../../assets/tooltip_star.svg';

type CoachMapTooltipProps = {
  coachName?: string;
  distanceFromUserInMiles?: number;
  rating?: number;
};

function CoachMapTooltip({ coachName, distanceFromUserInMiles, rating }: CoachMapTooltipProps) {
  return (
    <TooltipWrapper>
      <CoachNameText>{coachName || 'Coach'}</CoachNameText>
      <MoreStatsWrapper>
        <StatWrapper>
          <img src={DistanceIcon} style={{ height: '25px', width: '25px' }} />
          <StatInfoWrapper>
            <PrimaryText>{distanceFromUserInMiles || 0} miles</PrimaryText>
            <SecondaryText>Distance</SecondaryText>
          </StatInfoWrapper>
        </StatWrapper>

        <StyledDivider />

        <StatWrapper>
          <img src={TooltipStarIcon} style={{ height: '25px', width: '25px' }} />
          <StatInfoWrapper sx={{ paddingRight: '5px' }}>
            <PrimaryText>{rating || 0} star</PrimaryText>
            <SecondaryText>Rating</SecondaryText>
          </StatInfoWrapper>
        </StatWrapper>
      </MoreStatsWrapper>
    </TooltipWrapper>
  );
}

export default CoachMapTooltip;

const TooltipWrapper = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  minWidth: '236px',
  borderRadius: '4px',
  bottom: 60,
  padding: '12px',
  paddingTop: '16px',
  backgroundColor: colors.input_text,
  transform: 'translateX(-36%)',
}));

const CoachNameText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '22px',
  color: colors.white,
  textAlign: 'center',
  letterSpacing: '-0.4px',
  lineHeight: '32px',
  marginBottom: 16,
  textTransform: 'capitalize',
}));

const MoreStatsWrapper = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '100%',
  padding: '8px 12px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#384046',
}));

const StatWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

const StatInfoWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const PrimaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
`;

const SecondaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  color: #9ea3ae;
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  width: 1px;
  height: 62%;
  transform: rotate(90deg);
  background: #4d5461;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
