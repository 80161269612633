import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import CustomPdfViewer from '../components/CustomPdfViewer';
import privacy_policy from '../static/privacy_policy.pdf';
import GymniesFooter from '../components/GymniesFooter';

function PrivacyPolicyPage() {
  return (
    <>
      <Navbar />
      <CustomPdfViewer pdfFile={privacy_policy} />
      <GymniesFooter />
    </>
  );
}

export default PrivacyPolicyPage;
