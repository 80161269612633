import React from 'react';
import {
  TextField,
  createTheme,
  ThemeProvider,
  StandardTextFieldProps,
  TextareaAutosize,
} from '@mui/material';
import { colors } from '../../utils/constants/colors';

const theme = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          // minWidth: 368,
          width: '100%',
          border: '1px solid',
          borderColor: colors.input_border,
          backgroundColor: colors.input_bg,
          '&.Mui-error': {
            borderColor: colors.theme_red,
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
        input: {
          color: colors.input_text,
          backgroundColor: colors.input_bg,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          paddingBottom: 8,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'DM Sans',
          color: colors.input_label,
          // textTransform: 'uppercase',
          // letterSpacing: 1,
          letterSpacing: '-0.36px',
          paddingLeft: 6,
          // * comment this to animate label on focus
          // transform: 'translate(12px, 10px) scale(0.7)',
          '&.Mui-error': {
            color: colors.theme_red,
          },

          '&.Mui-focused': {
            color: colors.input_label,
            // textTransform: 'uppercase',
            // letterSpacing: 1,
            letterSpacing: '-0.36px',
            paddingLeft: 6,
            '&.Mui-error': {
              color: colors.theme_red,
            },
          },
        },
      },
    },
  },
});

// * prop types
type CustomInputProps = {
  label: string;
  defaultValue?: string;
  textInputProps?: StandardTextFieldProps;
  inputProps?: any;
  inputLabelProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customStyle?: Record<string, any>;
  onInputChange?: (e: any) => void;
  customInputProps?: any;
  error?: boolean;
  errorMessage?: string;
  value?:string;
};

/**
 *
 * Custom input field
 * @param label string
 * @param defaultValue string
 * @param textInputProps StandardTextFieldProps
 *
 * @returns TextField JSX
 */
function CustomInput({
  label,
  defaultValue,
  textInputProps,
  inputProps,
  inputLabelProps,
  customStyle,
  customInputProps,
  onInputChange,
  error,
  errorMessage,
  value
}: CustomInputProps) {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        id='filled-basic'
        margin='normal'
        label={label}
        defaultValue={defaultValue}
        variant='filled'
        InputProps={{ disableUnderline: true, ...inputProps }}
        {...textInputProps}
        InputLabelProps={inputLabelProps}
        sx={{ ...customStyle }}
        onChange={onInputChange}
        inputProps={{ ...customInputProps }}
        error={error}
        helperText={errorMessage}
        type={'number'}
        value={value}
      />
    </ThemeProvider>
  );
}

export default CustomInput;

type CustomTextareaProps = {
  label: string;
};

/**
 *
 * Custom textarea
 * @param label string
 *
 * @returns Textarea JSX
 */
export const CustomTextareaInput = ({ label }: CustomTextareaProps) => {
  return (
    <ThemeProvider theme={theme}>
      <TextareaAutosize
        minRows={7}
        maxRows={10}
        placeholder={label}
        style={{
          fontFamily: 'DM Sans',
          fontSize: 16,
          paddingLeft: 16,
          paddingTop: 16,
          color: colors.input_text,
          width: '100%',
          marginTop: 15,
          backgroundColor: colors.input_bg,
          border: '1px solid',
          borderColor: colors.input_border,
          borderRadius: 8,
          resize: 'vertical',
          outline: 'none',
        }}
      />
    </ThemeProvider>
  );
};
