import { useEffect, useState } from 'react';

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState([window.innerHeight, window.innerWidth]);

  const onResizeWindow = () => {
    setDimensions([window.innerHeight, window.innerWidth]);
  };
  useEffect(() => {
    window.addEventListener('resize', onResizeWindow);
    return () => {
      window.removeEventListener('resize', onResizeWindow);
    };
  }, []);

  return [dimensions[0], dimensions[1]];
};
