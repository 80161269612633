/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
  endAt,
  updateDoc,
  // GeoPoint,
} from 'firebase/firestore';
import * as geofire from 'geofire-common';

import { auth, firebaseDb } from '../firebaseConfig';
import { store } from '../store/store';

export const IsUserTokenExpired = (user: any) => {
  if (
    user?.stsTokenManager?.expirationTime &&
    new Date() > new Date(user?.stsTokenManager?.expirationTime + 100 * 60 * 60 * 24)
  ) {
    return true;
  }
  return false;
};

export const modifyUserObject = (obj: any) => {
  const updatedUserObj = { ...obj?.user, apiKey: null, auth: null, proactiveRefresh: null };
  return updatedUserObj;
};

// Function to get User from firebase based on Id to check whether user already exists
export const getUserFromFirebase = async (id: string) => {
  const user = doc(firebaseDb, 'users', id);
  const userRef = await getDoc(user);
  const querySnapshotData = await userRef.data();
  return querySnapshotData;
};

// Function to add user details to firestore for initial signup
export const addNewUserToFirebase = async (userData: any) => {
  const authStateFromRedux = store.getState().Auth;
  const newUserObj = {
    email: userData?.email,
    fullname: userData?.fullname,
    username: userData?.username,
    intagramProfileLink: userData?.instagram,
    isCoach: false,
    phoneNumber: authStateFromRedux?.user?.phoneNumber,
    profilePictureUrl: authStateFromRedux?.user?.photoURL,
    createdAt: authStateFromRedux?.user?.metadata?.createdAt,
  };
  // console.log('uid', auth?.currentUser?.uid, newUserObj, authStateFromRedux);
  const dbRef = await doc(
    firebaseDb,
    'users',
    auth?.currentUser?.uid || authStateFromRedux.user.uid,
  );
  const user = await setDoc(dbRef, newUserObj);
  return user;
};

export const getCoachListBasedOnLocation = async (userCoords: any, radiusInKM: number) => {
  try {
    const radiusInM = radiusInKM * 1000; // * km to m
    const usersCollectionRef = collection(firebaseDb, 'users');

    // const center = new GeoPoint(userCoords?.[0], userCoords?.[1]);

    const bounds = geofire.geohashQueryBounds(userCoords, radiusInM);

    const coachListData: Array<any> = [];
    for (const b of bounds) {
      const queryAsPerBounds = query(
        usersCollectionRef,
        where('isCoach', '==', true),
        orderBy('position.geohash'),
        startAt(b[0]),
        endAt(b[1]),
      );

      const coachesInCurrentBound = await getDocs(queryAsPerBounds);
      coachesInCurrentBound.forEach((doc) => {
        if (doc.data()) {
          coachListData.push({ ...doc.data(), id: doc.id });
        }
      });
    }

    const finalCoachList: Array<any> = [];
    // * removing false positives
    for (const coach of coachListData) {
      const coachLat = coach?.position?.geopoint?._lat;
      const coachLong = coach?.position?.geopoint?._long;
      const distanceInKm = geofire.distanceBetween([coachLat, coachLong], userCoords);

      const distanceInM = distanceInKm * 1000;
      const distanceInMiles = Math.round(distanceInKm / 1.609);

      const coachWithDistanceFromUser = { ...coach };
      coachWithDistanceFromUser['distanceFromUserInMiles'] = distanceInMiles;

      if (distanceInM <= radiusInM) {
        finalCoachList.push(coachWithDistanceFromUser);
      }
    }

    return finalCoachList;
  } catch (err) {
    // console.log('err', err);
    return [];
  }
};

export const checkIsMobileDevice = () => {
  const details = navigator.userAgent;

  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);

  return isMobileDevice;
};

/**
 * Function to get all coaches list based on filters applied
 * @param filters 
 * @returns 
 */
export const getCoachesList = async (filters: any) => {
  console.log(filters);
  // const users = doc(firebaseDb, 'users');
  const coaches: Array<Record<string, any>> = [];

  const queryConstraints = [];

  // filter only coaches
  queryConstraints.push(where('isCoach', '==', true));

  // if  speciality filter selected filter based on speciality
  if (filters.specialityFilter != '' && filters.specialityFilter?.length > 0 ) {
    const specialities: Array<string> = [];
    filters.specialityFilter.map((spec: any) => specialities.push(spec.label));
    queryConstraints.push(where('coachDetails.specialities', 'array-contains-any', specialities));
  }

  // if  gender filter selected filter based on gender
  if (filters.genderFilter != '' && filters.genderFilter != 'do not wish to  mention') {
    queryConstraints.push(where('coachDetails.gender', '==', filters?.genderFilter));
  }

  const q = query(collection(firebaseDb, 'users'), ...queryConstraints);

  const userRef = await getDocs(q);
  const querySnapshotData = await userRef.docs;
  querySnapshotData.forEach((data) => coaches.push({ ...data.data(), id: data.id }));

  let finalCoachList = [...coaches];

  // * if distance filter is selected filter based on distance
  if (filters.distanceFilter) {
    finalCoachList = finalCoachList.filter((coach) => {
      const coachLat = coach?.position?.geopoint?._lat;
      const coachLong = coach?.position?.geopoint?._long;

      const userLat = filters?.userLocation?.lat;
      const userLong = filters?.userLocation?.long;

      if (userLat && userLong) {
        const distanceInKm = geofire.distanceBetween([coachLat, coachLong], [userLat, userLong]);

        const distanceInMiles = Math.round(distanceInKm / 1.609);

        if (distanceInMiles <= filters?.distanceFilter) {
          return coach;
        }
      }
    });
  }

  // console.log('finalCoachList', finalCoachList);

  return finalCoachList;
};

/**
 * Function to calculate distance from coach and user
 * @param coachCoordinates 
 * @param userCoordinates 
 * @returns 
 */
export const getCoachDistanceFromUser = (coachCoordinates: any, userCoordinates: any) => {
  // console.log('loc', coachCoordinates, userCoordinates);
  if (coachCoordinates != null && userCoordinates != null) {
    const distanceInKm = geofire.distanceBetween(
      [coachCoordinates._lat, coachCoordinates._long],
      userCoordinates,
    );

    // const distanceInM = distanceInKm * 1000;s
    const distanceInMiles = Math.round(distanceInKm / 1.609);
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const formattedValue = formatter.format(distanceInMiles);

    return `${formattedValue || 0}`;
  } else return 0;
};

/**
 * Function to get coach services based on coach Id
 * @param id 
 * @returns 
 */

export const getCoachServicesBasedOnId = async (id: string,) => {
  const services: any = [];
  const queryConstraints = [];

  const currentUserId = store.getState().Auth.user?.uid;

  if(currentUserId != id){
    queryConstraints.push(where('isActive', '==', true))
  }

  const q = query(collection(firebaseDb, `users/${id}/coach-services`), ...queryConstraints);
  const servicesRef = await getDocs(q);
  const querySnapshotData = await servicesRef.docs;
  await querySnapshotData.forEach((data) => services.push({ ...data.data() }));
  return services;
}

/**
 * Function to get reference to firebase collection about coach services 
 * @param id 
 * @returns 
 */
export const coachServicesRef = (id: string | undefined) => {

  const queryConstraints = [];
  const currentUserId = store.getState().Auth.user?.uid;

  if(currentUserId != id){
    queryConstraints.push(where('isActive', '==', true))
  }

  const q = query(collection(firebaseDb, `users/${id}/coach-services`), ...queryConstraints);
  return q;
}

/**
 * function to update coach service availability
 * @param coachId 
 * @param serviceId 
 * @param data 
 * @returns 
 */
export const updateCoachServiceDetails = async(coachId: string | undefined, serviceId: string, data: any) => {
  const serviceRef = doc(firebaseDb, `users/${coachId}/coach-services/${serviceId}`);
  const updateService = await updateDoc(serviceRef, data);
  return updateService;
}
