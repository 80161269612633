import React, { useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import CoachMarker, { K_SIZE, MarkerPin } from './CoachMarker';
import { UserLocation } from '../../utils/interfaces';

type CoachMapProps = {
  userLocation: UserLocation | null;
  coachList: Array<any> | null;
};

function CoachMap({ userLocation, coachList }: CoachMapProps) {
  const defaultProps = useMemo(
    () => ({
      center: {
        lat: 40.73061, // * NY, USA
        lng: -73.935242,
      },
      zoom: 12,
    }),
    [],
  );

  const userCenter =
    userLocation?.lat && userLocation?.long
      ? { lat: userLocation?.lat, lng: userLocation?.long }
      : defaultProps.center;

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '' }}
        defaultCenter={defaultProps.center}
        center={userCenter as any}
        defaultZoom={defaultProps.zoom}
        hoverDistance={K_SIZE / 2}
      >
        {userLocation && <MarkerPin lat={userLocation?.lat} lng={userLocation?.long} />}

        {coachList?.map(
          (coach, index) =>
            coach?.position?.geopoint?._lat &&
            coach?.position?.geopoint?._long && (
              <CoachMarker
                key={`coach_on_map_${index}`}
                image={coach?.coachDetails?.identity?.identityMediaPath}
                lat={coach?.position?.geopoint?._lat}
                lng={coach?.position?.geopoint?._long}
                coachName={coach?.fullname}
                distanceFromUserInMiles={coach?.distanceFromUserInMiles}
                rating={coach?.coachDetails?.rating}
              />
            ),
        )}
        {/* <CoachMarker lat={10.96} lng={77.04} />
        <CoachMarker lat={10.94} lng={76.98} /> */}
      </GoogleMapReact>
    </div>
  );
}

export default CoachMap;
