import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Divider, Grid, InputBase, Stack, Typography } from '@mui/material';
import Navbar from '../components/Navbar/Navbar';
import { FilterAltOutlined, Search } from '@mui/icons-material';
import styled from '@emotion/styled';
// import Dropdown from '../components/Dropdown';
// import CustomSlider from '../components/CustomSlider';
import { CoachListingCard } from '../components/CoachListingCard/CoachListingCard';
import GymniesFooter from '../components/GymniesFooter';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
// import CloseCircle from '../assets/close_black.svg';
// import CustomRating from '../components/CustomRating';
import CoachMap from '../components/CoachMap';
import {
  checkIsMobileDevice,
  getCoachesList,
  getCoachListBasedOnLocation,
} from '../utils/helperFunctions';
import { UserLocation } from '../utils/interfaces';
import FilterMenu from '../components/FilterMenu';
import { useWindowDimensions } from '../utils/hooks/useWindowDimensions';
import { addFilters, FiltersState, removeFilters } from '../store/Filters/FiltersSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

interface GetUserLocationPromiseType extends UserLocation {
  available: boolean;
  error?: GeolocationPositionError;
}

const CoachListingPage = () => {
  const navigate = useNavigate();
  const geolocationAPI = navigator?.geolocation;

  const dispatch = useAppDispatch();
  const filtersState = useAppSelector(FiltersState);

  const [windowHeight, windowWidth] = useWindowDimensions();
  const isMobile = windowWidth <= 768;

  const [coachList, setCoachList] = useState<Array<Record<string, any>>>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);
  const [nearbyCoachList, setNearbyCoachList] = useState<Array<any> | null>(null);
  const [isLocationAccessGranted, setIsLocationAccessGranted] = useState<boolean | null>(null);
  const [priceSliderValue, setPriceSliderValue] = useState(10);
  const [languageValue, setLanguageValue] = useState<string>('none');
  const [loading, setLoading] = useState<boolean>(false);

  const handleFilterBtn = () => {
    setShowFilters(!showFilters);
  };

  const handleCoachProfilePage = (coachId: string) => {
    navigate(`/coach-profile/${coachId}`);
  };

  const getUserCoordinates = () => {
    return new Promise((resolve, reject) => {
      if (!geolocationAPI) {
        // * Geolocation API is not available in your browser!
        reject({ available: false });
      } else {
        geolocationAPI.getCurrentPosition(
          (position) => {
            const { coords } = position;
            const locationCoords = { lat: coords.latitude, long: coords.longitude };
            setUserLocation(locationCoords);

            resolve({ available: true, ...locationCoords });
          },
          (error) => {
            // * Something went wrong while getting your position!
            reject({ available: false, error });
          },
        );
      }
    }) as Promise<GetUserLocationPromiseType>;
  };

  const getUserCoordsFromIpApi = async (distanceFilterInKM: number) => {
    // const IP_API_URL = 'http://ip-api.com/json/?fields=status,lat,lon';
    const IP_API_URL = 'https://ipapi.co/latlong/';

    try {
      const locationResponse = await axios.get(`${IP_API_URL}`);
      const coordinatesData = locationResponse?.data?.split(',');
      const locationCoords = {
        lat: +coordinatesData[0],
        long: +coordinatesData[1],
      };

      if (locationCoords?.lat && locationCoords?.long) {
        // * fetch all nearby coaches from db - as per distance filter
        // const coachList = await getCoachListBasedOnLocation(
        //   [locationCoords?.lat, locationCoords?.long],
        //   distanceFilterInKM,
        // );
        getCoachesList({
          genderFilter: filtersState.genderValue,
          specialityFilter: filtersState.specialityValue,
          distanceFilter: filtersState.distanceSliderValue,
          userLocation: locationCoords,
        }).then((res) => {
          setLoading(false);
          console.log('res', res);
          if (res?.length > 0) {
            setCoachList(res);
          } else {
            setCoachList([]);
          }
        });

        setCoachList(coachList);
        setNearbyCoachList(coachList);
        setUserLocation(locationCoords);
      }
    } catch (err) {
      // console.log('error getting location from geolocation db!', err);
    }
  };

  async function getUserLocation() {
    const distanceFilterInKM = filtersState.distanceSliderValue * 1.609; // * miles to km

    try {
      const locationResponse = await getUserCoordinates();

      if (locationResponse.available) {
        // * fetch all nearby coaches from db - as per distance filter
        const coachList = await getCoachListBasedOnLocation(
          [locationResponse?.lat, locationResponse?.long],
          distanceFilterInKM,
        );

        setNearbyCoachList(coachList);
      }
    } catch (err: any) {
      // * get location coords using geolocation-db if have no location access
      if (!err.available) {
        getUserCoordsFromIpApi(distanceFilterInKM);
      }
    }
  }

  useEffect(() => {
    // * check user location changes in navigator
    // if (!checkIsMobileDevice()) {
    navigator?.permissions?.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
        setIsLocationAccessGranted(permissionStatus.state == 'granted');
      };
    });
    // }
  }, []);

  // * get nearby coach list on updating distance filter or location access change
  useEffect(() => {
    if (isLocationAccessGranted) {
      getUserLocation();
    } else {
      getUserCoordsFromIpApi(filtersState.distanceSliderValue * 1.609);
    }
  }, [isLocationAccessGranted]);

  const handleDistanceFilterChange = (value: number) => {
    dispatch(
      addFilters({
        distance: value,
        gender: filtersState.genderValue,
        speciality: filtersState.specialityValue,
      }),
    );
  };

  const handlePriceFilterChange = (value: number) => {
    setPriceSliderValue(value);
  };

  const handleGenderFilterChange = (value: string) => {
    dispatch(
      addFilters({
        distance: filtersState.distanceSliderValue,
        gender: value,
        speciality: filtersState.specialityValue,
      }),
    );
  };

  const handleSpecialityFilterChange = (value: any) => {
    dispatch(
      addFilters({
        distance: filtersState.distanceSliderValue,
        gender: filtersState.genderValue,
        speciality: value,
      }),
    );
  };

  const handleLanguageFilterChange = (value: string) => {
    setLanguageValue(value);
  };

  const handleApplyFilters = () => {
    setLoading(true);
    getCoachesList({
      genderFilter: filtersState.genderValue,
      specialityFilter: filtersState.specialityValue,
      //TODO: commenting price filter for now will use once coaches available.
      // priceFilter: priceSliderValue,
      //TODO: commenting distance filter for now will use once coaches available
      distanceFilter: filtersState.distanceSliderValue,
      userLocation,
    }).then((res) => {
      setLoading(false);
      // console.log('res', res);
      if (res?.length > 0) {
        setCoachList(res);
      } else {
        setCoachList([]);
      }
    });
  };

  const resetFilters = () => {
    setLoading(true);
    dispatch(removeFilters());
    getCoachesList({
      genderFilter: '',
      specialityFilter: [],
      // priceFilter: priceSliderValue,
      distanceFilter: 5,
      userLocation,
    }).then((res) => {
      setLoading(false);
      console.log('res', res);
      if (res?.length > 0) {
        setCoachList(res);
      } else {
        setCoachList([]);
      }
    });
  };

  return (
    <>
      <Navbar />
      <Grid
        container
        sx={{
          padding: '10px 16px',
          paddingTop: '110px',
          boxSizing: 'border-box',
        }}
        md={12}
      >
        <Grid
          item
          lg={3}
          md={4}
          xl={2}
          sx={{
            padding: '6px 12px',
            '@media (max-width: 768px)': {
              width: '100%',
              padding: 0,
            },
          }}
        >
          <Stack
            spacing={2}
            sx={{
              '@media (max-width: 768px)': {
                alignItems: 'center',
              },
            }}
          >
            {/* TODO : needs to be integrated once data finalised & search will activated once data available */}
            {/* NOTE: not to delete below commented code */}

            {/* <FilterBox>
              <SearchBox>
                <Search />
                <InputBase placeholder='search' sx={{ paddingLeft: '6px' }} />
              </SearchBox>
            </FilterBox>
            <FilterBox
              style={{
                padding: 0,
              }}
              sx={{
                '@media (max-width: 768px)': {
                  display: 'none',
                },
              }}
            >
              <Divider sx={{ width: '100%' }} />
            </FilterBox> */}

            <FilterBtnBox>
              <CustomButton
                label='Filter'
                type='outlined'
                customStyle={{
                  width: '100%',
                  justifyContent: 'space-between',
                  fontWeight: 700,
                  borderRadius: '12px',
                  height: '50px',
                }}
                btnColor='#000'
                icon={<FilterAltOutlined />}
                onClickFunc={handleFilterBtn}
              />
            </FilterBtnBox>

            <CustomBox1>
              {showFilters || !isMobile ? (
                <FilterMenu
                  handleDistanceFilterChange={handleDistanceFilterChange}
                  handlePriceFilterChange={handlePriceFilterChange}
                  handleGenderFilterChange={handleGenderFilterChange}
                  handleSpecialityFilterChange={handleSpecialityFilterChange}
                  handleLanguageFilterChange={handleLanguageFilterChange}
                  // genderValue={filtersState.genderValue}
                  // specialityValue={filtersState.specialityValue}
                  languageValue={languageValue}
                  priceSliderValue={priceSliderValue}
                  // distanceSliderValue={filtersState.distanceSliderValue}
                  handleApplyFilterOnMobile={handleApplyFilters}
                  handleResetFilters={resetFilters}
                />
              ) : null}
            </CustomBox1>

            <CustomBox2>
              {/* {!isMobile ? (
                <FilterMenu
                  handleDistanceFilterChange={handleDistanceFilterChange}
                  handlePriceFilterChange={handlePriceFilterChange}
                  handleGenderFilterChange={handleGenderFilterChange}
                  handleSpecialityFilterChange={handleSpecialityFilterChange}
                  handleLanguageFilterChange={handleLanguageFilterChange}
                  genderValue={genderValue}
                  specialityValue={specialityValue}
                  languageValue={languageValue}
                  priceSliderValue={priceSliderValue}
                  distanceSliderValue={distanceSliderValue}
                />
              ) : null} */}
            </CustomBox2>
          </Stack>
        </Grid>

        <Grid
          item
          lg={5}
          md={8}
          xl={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '8px',
            alignItems: 'center',
            '@media (max-width: 768px)': {
              width: '100%',
              padding: 0,
            },
            // '@media (min-width: 769px) and (max-width:1024px)': {
            //   width: '60%',
            // },
          }}
        >
          {loading ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : coachList.length > 0 ? (
            coachList.map((coachData, idx) => (
              <Box key={`coach_card_${idx}`} onClick={() => handleCoachProfilePage(coachData?.id)}>
                <CoachListingCard data={coachData} currentUserLocation={userLocation} />
              </Box>
            ))
          ) : (
            <Typography>No Coaches found</Typography>
          )}
        </Grid>
        <Grid
          item
          md={4}
          xl={5}
          sx={{
            width: '100%',
            // '@media (max-width: 1024px)': {
            //   display: 'none',
            // },
            '@media (max-width: 768px)': {
              paddingTop: '1.5rem',
              paddingBottom: '2rem',
            },
          }}
        >
          <CoachMap userLocation={userLocation} coachList={nearbyCoachList} />
        </Grid>
      </Grid>
      <GymniesFooter />
    </>
  );
};

const SearchBox = styled(Box)`
  display: flex;
  align-items: center;
  background: #f9fafb;
  border: 1px solid #e5e6eb;
  border-radius: 8px;
  padding: 6px 12px;
  margin: 10px 0px;
  min-width: 256px;
  @media (max-width: 768px) {
    width: 100%;
    // padding: 10px 10%;
    padding: 10px;
  }
`;

const FilterBox = styled(Box)`
  @media (max-width: 768px) {
    width: 100%;
    // padding: 10px 10%;
    padding: 10px 0px;
  }
`;

const FilterBtnBox = styled(FilterBox)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin-top: 0px !important;
    // padding: 10px 10%;
  }
`;

const CustomBox1 = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  padding: 10px;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const CustomBox2 = styled(Box)`
  display: none;
  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
    padding: 10px;
  }
`;

export default CoachListingPage;
