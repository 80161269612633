export const colors = {
  theme_red: '#EE2737',
  theme_blue: '#3772FF',
  input_bg: '#F9FAFB',
  input_border: '#E5E6EB',
  input_text: '#192126',
  input_label: '#9EA3AE',
  white: '#FFF',
  header_text: '#12022F',
  paragraph_text: '#594D6D',
  original_price_purple: '#594D6D',
  dropdown_border: '#E5E6EB',
  slider_tooltip_bg: '#141416',
  slider_tooltip_text: '#FCFCFD',
  radio_border_black: '#1B1B1B',
  light_blue_bg: '#F7FAFF',
  light_gray_input: '#E7E5EA',
  testimonial_orange: '#FF6D43',
  dark_gray: '#777E90',
  download_app_text: '#23262F',
  light_white: '#F5F5F5',
  coach_card_line: '#4D5461',
};
