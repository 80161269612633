import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { CustomButton } from '../CustomButton/CustomButton';
import CourseBadge from '../../assets/course_badge.svg';
import BlackStar from '../../assets/black_star.svg';
import DummyCourse from '../../assets/course_img.png';
import ArrowRightWhite from '../../assets/arrow_right_white.svg';
import styled from '@emotion/styled';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CourseCard = ({ data }: Props) => {
  return (
    <StyledCard>
      <img
        src={DummyCourse}
        style={{ width: '100%', objectFit: 'cover', height: '200px', borderRadius: '12px' }}
      />

      <div style={{ position: 'absolute', top: 0, right: '22px' }}>
        <img
          src={CourseBadge}
          style={{ position: 'absolute', top: '0%', right: 0, height: '70px', width: '60px' }}
        />
        <img
          src={BlackStar}
          style={{
            position: 'absolute',
            top: '30px',
            right: '20px',
            height: '20px',
            width: '20px',
          }}
        />
      </div>

      <Stack sx={{ padding: '4px' }} spacing='15px'>
        <PrimaryText variant='h4'>Full Body Workout</PrimaryText>
        <StyledContainer>
          <StyledBox>
            <Avatar
              src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
              sx={{ height: '55px', width: '55px' }}
            />
            <SecondaryText>23,545 participants</SecondaryText>
          </StyledBox>
          <PriceText>$35</PriceText>
        </StyledContainer>
        <CustomButton
          label={'Join Course'}
          type='contained'
          btnColor='#192126'
          customStyle={{
            width: '100%',
            padding: '10px 24px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '-0.3px',
          }}
          icon={<img src={ArrowRightWhite} />}
        />
      </Stack>
    </StyledCard>
  );
};

const StyledCard = styled(Box)`
  border: 1px solid #e5e6eb;
  border-radius: 8px;
  position: relative;
  min-width: 320px;
  min-height: 420px;
  box-sizing: border-box;
  padding: 16px;
`;

const PrimaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  @media (min-width: 600px) {
    padding: 0px;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  gap: 14px;
  align-items: center;
`;

const SecondaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const PriceText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  letter-spacing: -0.4px;
`;
