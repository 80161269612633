import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface FiltersState {
    genderValue: string,
    specialityValue: any,
    distanceSliderValue: number,
    languageValue: string,
    // priceSliderValue: number,
}

const initialState: FiltersState = {
    genderValue: '',
    specialityValue: [],
    distanceSliderValue: 5,
    languageValue: '',
    // priceSliderValue: 10,
};

export const FiltersSlice = createSlice({
  name: 'Filters',
  initialState,
  reducers: {
    addFilters: (state, action: PayloadAction<any>) => {
      state.distanceSliderValue = action.payload.distance,
      state.genderValue = action.payload.gender,
      state.specialityValue = action.payload.speciality
    },
    removeFilters: (state) => {
      state.distanceSliderValue = 5,
      state.specialityValue = [],
      state.genderValue = ''
    },
  },
});

// Action creators are generated for each case reducer function
export const { addFilters, removeFilters } = FiltersSlice.actions;

export const FiltersState = (state: RootState) => state.Filters;

export default FiltersSlice.reducer;
