import React from 'react';
import { colors } from '../../utils/constants/colors';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/system';
import { Typography, TypographyProps, Stack } from '@mui/material';
import gymnies_logo from '../../assets/gymnies_logo.svg';
import twitter_icon from '../../assets/twitter_icon.svg';
import facebook_icon from '../../assets/facebook_icon.svg';
import instagram_icon from '../../assets/instagram_icon.svg';
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import { useNavigate } from 'react-router-dom';

function GymniesFooter() {
  const navigate = useNavigate();

  const handleAboutPage = () => {
    navigate('/about');
  };

  const handlePrivacyPolicyPage = () => {
    navigate('/privacy-policy');
  };

  const handleTermsAndConditionsPage = () => {
    navigate('/terms-and-conditions');
  };

  const navigateToContactForm = () => {
    navigate('/about', { state: { targetId: 'contact-form' } });
  };

  return (
    <>
      <FooterSectionContainer
        sx={{
          marginLeft: {
            xs: '10%',
            sm: '10%',
            md: '10%',
            lg: '10%',
            xl: '25%',
          },
          marginRight: {
            xs: '10%',
            sm: '10%',
            md: '10%',
            lg: '10%',
            xl: '25%',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            borderBottom: '1px solid',

            borderColor: colors.light_gray_input,
            paddingBottom: '80px',
            '@media(max-width:768px)': {
              border: 'none',
              paddingBottom: '20px',
            },
          }}
        >
          <CommonBox>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '40px',
                }}
              >
                <FooterTitleText onClick={handleAboutPage}>About</FooterTitleText>
                <FooterTitleText onClick={handlePrivacyPolicyPage}>Privacy Policy</FooterTitleText>
                <FooterTitleText onClick={handleTermsAndConditionsPage}>
                  Terms & Conditions
                </FooterTitleText>
              </Box>
            </Box>
          </CommonBox>
          <Box>
            <img src={gymnies_logo} alt='gymnies logo' />
          </Box>

          <CommonBox sx={{ display: 'flex', justifyContent: 'flex-end', gap: '39px' }}>
            <FooterTitleText onClick={navigateToContactForm}>Contact Us</FooterTitleText>
            <Box sx={{ display: 'flex', gap: '32px' }}>
              <img
                src={twitter_icon}
                alt='gymnies twitter page'
                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
              />
              <img
                src={facebook_icon}
                alt='gymnies facebook page'
                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
              />
              <img
                src={instagram_icon}
                alt='gymnies instagram page'
                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
              />
            </Box>
          </CommonBox>
        </Box>
        <MobileFooterListOptions>
          <FooterTitleText onClick={handleAboutPage}>About</FooterTitleText>
          <FooterTitleText onClick={handlePrivacyPolicyPage}>Privacy Policy</FooterTitleText>
          <FooterTitleText onClick={handleTermsAndConditionsPage}>
            Terms & Conditions
          </FooterTitleText>
          <FooterTitleText>Contact Us</FooterTitleText>
          <Box sx={{ display: 'flex', gap: '25px' }}>
            <img
              src={twitter_icon}
              alt='gymnies twitter page'
              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
            />
            <img
              src={facebook_icon}
              alt='gymnies facebook page'
              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
            />
            <img
              src={instagram_icon}
              alt='gymnies instagram page'
              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
            />
          </Box>
        </MobileFooterListOptions>

        <Box>
          <CopyrightText>&#169; {STRING_CONSTANTS.copyright_text}</CopyrightText>
        </Box>
      </FooterSectionContainer>
    </>
  );
}

export default GymniesFooter;

const FooterSectionContainer = styled(Box)<BoxProps>(() => ({
  height: '291px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: colors.white,
  '@media(max-width:768px)': {
    height: 'initial',
    padding: '10px 0px',
  },
}));

const CommonBox = styled(Box)<BoxProps>(() => ({
  width: '42%',
  '@media(max-width:768px)': {
    display: 'none',
  },
}));

const MobileFooterListOptions = styled(Stack)(() => ({
  display: 'none',
  '@media(max-width:768px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
}));

const FooterTitleText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-0.36px',
  lineHeight: '26px',
  cursor: 'pointer',
  // marginBottom: 17,
}));

const CopyrightText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.3px',
  lineHeight: '24px',
  margin: '40px 0px',
  '@media(max-width:768px)': {
    margin: '20px 0px',
  },
}));
