import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'react-range-slider-input/dist/style.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const container: any = document.getElementById('root');
// const root = createRoot(container);

// * https://github.com/google-map-react/google-map-react/issues/1117
// root.render(
//   <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   </React.StrictMode>,
// );

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
