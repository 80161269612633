import React from 'react';
import { Grid } from '@mui/material';
import ServiceCard from '../ServiceCard';
// import { Masonry } from '@mui/lab';
import { useAppSelector } from '../../store/hooks';
import { AuthState } from '../../store/Auth/AuthSlice';

interface Props {
  servicesData: any;
  coachId?: string;
}

export const ServicesSection = ({ servicesData, coachId }: Props) => {
  const Auth = useAppSelector(AuthState);

  return (
    <Grid item container gap={5} sx={{ padding: '8px' }}>
      <Grid item container gap={5} sx={{ padding: '8px' }}>
        {servicesData.length > 0 &&
          servicesData.map((service: any) => (
            <Grid sm={5.5} key={service?.docId}>
              <ServiceCard
                detailArray={service?.subscriptionDetails}
                serviceType={'one time'}
                serviceDetails={service}
                isCoachView={Auth.isAuthenticated ? Auth.user.uid == coachId : false}
                coachId={coachId}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

// Masonry layout for services

{
  /* <Masonry columns={{sx: 1, md:2, lg:3}} spacing={2}>
{servicesData.length > 0 && servicesData.map((service: any) =>
   // eslint-disable-next-line react/jsx-key
   <ServiceCard
     title={'Fullbody workout and mental wellness'}
     detailArray={service?.subscriptionDetails}
     serviceType={'one time'}
     serviceDetails={service}
     isCoachView={Auth.isAuthenticated ? Auth.user.uid == coachId : false}
     coachId={coachId}
   />
)}
</Masonry> */
}
