import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface props {
  children: any;
}

const RouterWrapper = ({ children }: props) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default RouterWrapper;
