import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps, createTheme, Grid, Tab, Tabs, ThemeProvider } from '@mui/material';
import Navbar from '../components/Navbar/Navbar';
import girl_exercising from '../assets/girl_exercising.svg';
import { colors } from '../utils/constants/colors';
import GymniesFooter from '../components/GymniesFooter';
import CoachCard from '../components/CoachCard';
import { ProfileTabs } from '../utils/constants/globalConstant';
import AboutSection from '../components/ProfileTabSections/AboutSection';
import PostSection from '../components/ProfileTabSections/PostSection';
import { ServicesSection } from '../components/ProfileTabSections/ServicesSection';
import CoursesSection from '../components/ProfileTabSections/CoursesSection';
import { useParams } from 'react-router-dom';
import { coachServicesRef, getCoachServicesBasedOnId, getUserFromFirebase } from '../utils/helperFunctions';
import { onSnapshot } from 'firebase/firestore';

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&. Mui-selected': {
            background: '#000',
            color: '#fff',
            borderRadius: '35px',
            height: '40px',
            padding: '0px',
            margin: '10px 0px',
            minHeight: '35px',
            textTransform: 'capitalize',
          },
        },
      },
    },
  },
});

function CoachProfilePage() {
  const [selectedTab, setSelectedTab] = useState(ProfileTabs.about);
  const [coachDetails, setCoachDetails] = useState<any>(null);
  const [services, setServices] = useState<any>([]);

  const handleTabChange = (e: React.SyntheticEvent, currentTab: any) => {
    setSelectedTab(currentTab);
  };

  const params = useParams();

  console.log('params', params, coachDetails, services);

  useEffect(() => {
    if (params?.id) {
      getUserFromFirebase(params?.id).then((res) => {
        setCoachDetails(res);
      });

      if (selectedTab == ProfileTabs.services) {
        getCoachServicesBasedOnId(params?.id)
          .then((res) => {
            console.log('triggered coach service call');
            setServices(res);
          })
          .catch((err) => {
            console.log('error fetching services', err);
          });
      }

   
    }

      const ref  = coachServicesRef(params?.id)

      const unsub = onSnapshot(ref, (snapshot: { docs: any[]; }) => {
        setServices(snapshot.docs.map((doc: any) => doc.data() ))
      });

      return () => {
        unsub();
      }
  }, [params?.id, selectedTab]);

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <CoachProfileContainer>
        <HeroBox>
          <img src={girl_exercising} alt='girl exercising' style={{ width: '100%' }} />
          <ProfileCardWrapper>
            <CoachCard coachData={coachDetails} />
          </ProfileCardWrapper>
        </HeroBox>
        {/* <ProfileCardWrapper>
          <CoachCard />
        </ProfileCardWrapper> */}
        <MainContentGrid item container md={12}>
          <Grid item sm={0} md={4} />
          <RightGrid item xs={12} sm={12} md={8}>
            <Tabs
              onChange={handleTabChange}
              value={selectedTab}
              sx={{}}
              textColor={'inherit'}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <Tab
                label={'About'}
                value={ProfileTabs.about}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '16px',
                  ...(selectedTab === ProfileTabs.about && {
                    background: '#000',
                    color: '#fff',
                    borderRadius: '35px',
                    height: '40px',
                    padding: '0px',
                    margin: '10px 0px',
                    minHeight: '35px',
                    textTransform: 'capitalize',
                  }),
                }}
              />
              {/* <Tab
                label={'Post'}
                value={ProfileTabs.post}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '16px',
                  ...(selectedTab === ProfileTabs.post && {
                    background: '#000',
                    color: '#fff',
                    borderRadius: '35px',
                    height: '40px',
                    padding: '0px',
                    margin: '10px 0px',
                    minHeight: '35px',
                    textTransform: 'capitalize',
                  }),
                }}
              ></Tab> */}
              <Tab
                label={'Services'}
                value={ProfileTabs.services}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '16px',
                  ...(selectedTab === ProfileTabs.services && {
                    background: '#000',
                    color: '#fff',
                    borderRadius: '35px',
                    height: '40px',
                    padding: '0px',
                    margin: '10px 0px',
                    minHeight: '35px',
                    textTransform: 'capitalize',
                  }),
                }}
              />
              <Tab
                label={'Courses'}
                value={ProfileTabs.courses}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'DM Sans',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '16px',
                  ...(selectedTab === ProfileTabs.courses && {
                    background: '#000',
                    color: '#fff',
                    borderRadius: '35px',
                    height: '40px',
                    padding: '0px',
                    margin: '10px 0px',
                    minHeight: '35px',
                    textTransform: 'capitalize',
                  }),
                }}
              />
            </Tabs>

            {selectedTab === ProfileTabs.about && <AboutSection coachData={coachDetails} />}
            {selectedTab === ProfileTabs.post && <PostSection />}
            {selectedTab === ProfileTabs.services && (
              <ServicesSection servicesData={services} coachId={params?.id} />
            )}
            {selectedTab === ProfileTabs.courses && <CoursesSection />}
          </RightGrid>
        </MainContentGrid>

        {/* <Box
          sx={{
            width: '100%',
            marginTop: '280px',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: {
              xs: '10%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '10%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            zIndex: 99,
            // alignItems: 'center',
          }}
        ></Box> */}
      </CoachProfileContainer>
      <GymniesFooter />
    </ThemeProvider>
  );
}

export default CoachProfilePage;

const CoachProfileContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: colors.light_white,
  paddingTop: '105px',
  // position: 'relative',
}));

const HeroBox = styled(Box)(() => ({
  position: 'relative',
  padding: 0,
}));

const ProfileCardWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '75%',
  left: '10%',
  '@media (min-width: 1024px) and (max-width: 1440px)': {
    left: '3%',
  },
  '@media (max-width : 1023px)': {
    position: 'initial',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-20px',
  },
}));

const MainContentGrid = styled(Grid)(() => ({
  minHeight: '500px',
  background: '#fff',
  '@media (max-width : 1023px)': {
    marginTop: '30px',
  },
}));

const RightGrid = styled(Grid)(() => ({
  minHeight: '500px',
  '@media (max-width : 1023px)': {
    padding: '10px 16px',
  },
}));
