import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import RangeSlider from 'react-range-slider-input';
import { styled } from '@mui/material/styles';
import { BoxProps, Typography } from '@mui/material';
import { colors } from '../../utils/constants/colors';
import slider_tooltip_down_arrow from '../../assets/slider_tooltip_down_arrow.svg';

type SliderType = 'distance' | 'price';
interface Props {
  type: SliderType;
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  handleFilterChange?: (value: number) => void;
}

function CustomSlider({ defaultValue, minValue, maxValue, type, handleFilterChange }: Props) {
  const [sliderUpperValue, setSliderUpperValue] = useState(defaultValue || 50);
  // * use this to show tooltip only while sliding
  // const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    defaultValue && setSliderUpperValue(defaultValue);
  }, [defaultValue]);

  const sliderMinText = () => {
    if (type == 'price') {
      return minValue ? `$ ${minValue}` : `$ ${0}`;
    } else {
      return minValue ? `${minValue} miles` : `${0} miles`;
    }
  };

  const sliderMaxText = () => {
    if (type == 'price') {
      return maxValue ? `$ ${maxValue}` : `$ ${100}`;
    } else {
      return maxValue ? `${maxValue} miles` : `${100} miles`;
    }
  };

  return (
    <Box sx={{ position: 'relative', marginBottom: '20px' }}>
      <SliderTooltip
        style={{
          left: type == 'price' ? `${sliderUpperValue}%` : `${sliderUpperValue / 1}%`,
          display: 'flex',
          justifyContent: 'center',
          transform: 'translate(-55%, 0%)',
          width: 'max-content',
          zIndex: 99,
        }}
      >
        {type == 'price'
          ? `$ ${Math.round(sliderUpperValue)}`
          : `${Math.round(sliderUpperValue)} miles`}
        <TooltipDownArrow>
          <img src={slider_tooltip_down_arrow} alt='' />
        </TooltipDownArrow>
      </SliderTooltip>
      <RangeSlider
        id='blue-range-slider'
        className='blue-slider'
        defaultValue={[0, 50]}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
        step={1}
        min={minValue ? minValue : 0}
        max={maxValue ? maxValue : 100}
        value={[0, sliderUpperValue]}
        onInput={(value: any) => {
          setSliderUpperValue(value[1]);
        }}
        // onThumbDragStart={() => setShowTooltip(true)}
        onThumbDragEnd={() => {
          if (handleFilterChange) {
            handleFilterChange(sliderUpperValue);
          }
        }}
      />
      <Typography sx={{ position: 'absolute', left: 0, top: 25 }}>{sliderMinText()}</Typography>
      <Typography sx={{ position: 'absolute', right: 0, top: 30 }}>{sliderMaxText()}</Typography>
    </Box>
  );
}

export default CustomSlider;

const SliderTooltip = styled(Box)<BoxProps>(() => ({
  minWidth: '30px',
  minHeight: '15px',
  position: 'absolute',
  top: '-35px',
  backgroundColor: colors.slider_tooltip_bg,
  color: colors.slider_tooltip_text,
  padding: '4px 8px',
  borderRadius: '8px',
  fontSize: '12px',
  fontWeight: '600',
}));

const TooltipDownArrow = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  bottom: '-7px',
}));
