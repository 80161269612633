import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AuthState {
  user: any;
  isAuthenticated: boolean;
  isAnonymous: boolean;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  isAnonymous: false,
};

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setUserInLocalStorage: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isAnonymous = false;
    },
    addUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isAnonymous = false;
    },
    removeUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isAnonymous = false;
      localStorage.removeItem('user');
    },
    addAnonymousUser: (state) => {
      state.isAnonymous = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUser, removeUser, setUserInLocalStorage, addAnonymousUser } = AuthSlice.actions;

export const AuthState = (state: RootState) => state.Auth;

export default AuthSlice.reducer;
