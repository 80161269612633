import React from 'react';
import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from '../utils/constants/colors';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import CustomInput from '../components/CustomInput';
import { CustomTextareaInput } from '../components/CustomInput/CustomInput';
import { CustomButton } from '../components/CustomButton/CustomButton';

import become_coach_image from '../assets/become_coach_image.svg';
import coach_avatar from '../assets/coach_avatar.svg';
import apple_icon from '../assets/apple_icon.svg';
import google_icon from '../assets/google_icon.svg';
import mobile_app_preview from '../assets/mobile_app_preview.svg';
import contact_image from '../assets/contact_image.svg';
import GymniesFooter from '../components/GymniesFooter';
import Navbar from '../components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';

const becomeCoachHeaderStyle = {
  display: {
    xs: 'inline',
    sm: 'inline',
    md: 'inline',
    lg: 'inline',
    xl: 'inline',
  },
};

function BecomeACoachPage() {
  const navigate = useNavigate();

  const navigateToContactForm = () => {
    navigate('/about', { state: { targetId: 'contact-form' } });
  };

  return (
    <>
      <Navbar />
      <BecomeCoachSectionContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            alignItems: 'center',
            '@media (max-width: 329px)': {
              padding: '0 0.5rem',
            },
            '@media (min-width: 330px) and (max-width: 600px)': {
              margin: '0 1rem',
            },
          }}
        >
          <Box
            sx={{
              marginBottom: '16px',
              marginTop: '6rem',
              width: '100%',
              textAlign: 'center',
              '@media (max-width: 500px)': {
                // display: 'flex',
                // flexDirection: 'row',
                // flexWrap: 'wrap',
                // gap: '6px',
              },
            }}
          >
            <BecomeGymniesCoachText
              variant='h1'
              sx={becomeCoachHeaderStyle}
            >{`Become a `}</BecomeGymniesCoachText>
            <BecomeGymniesCoachText
              variant='h1'
              style={{
                color: colors.theme_red,
                textDecoration: `underline solid ${colors.theme_red} 3px`,
                textUnderlineOffset: '6px',
              }}
              sx={becomeCoachHeaderStyle}
            >{`GYMNIES`}</BecomeGymniesCoachText>
            <BecomeGymniesCoachText
              variant='h1'
              sx={becomeCoachHeaderStyle}
            >{` Coach`}</BecomeGymniesCoachText>
          </Box>

          <BecomeCoachDescText>
            Download our App and follow the 9 steps to become a coach.
          </BecomeCoachDescText>

          <ButtonsWrapper
            sx={{
              marginBottom: '6rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              '@media (max-width: 600px)': {
                marginBottom: '40px',
              },
            }}
          >
            <ContactAndDownloadButton
              variant='contained'
              disableRipple
              sx={{
                width: '135px !important',
                minWidth: '130px !important',
                '@media (max-width: 500px)': {
                  display: 'flex',
                  flex: 1,
                },
              }}
              onClick={navigateToContactForm}
            >
              <ContactAndDownloadBtnText>Contact Us</ContactAndDownloadBtnText>
            </ContactAndDownloadButton>
            <ContactAndDownloadButton
              variant='contained'
              disableRipple
              style={{
                backgroundColor: colors.slider_tooltip_text,
                border: '2px solid #E6E8EC',
              }}
              sx={{
                '@media (max-width: 500px)': {
                  display: 'flex',
                  flex: 1,
                },
              }}
            >
              <ContactAndDownloadBtnText sx={{ color: `${colors.download_app_text} !important` }}>
                Download App
              </ContactAndDownloadBtnText>
            </ContactAndDownloadButton>
          </ButtonsWrapper>

          <Box
            sx={{
              marginBottom: '20px',
              '@media (max-width: 600px)': {
                marginBottom: '30px',
              },
              '@media (min-width: 600px) and (max-width: 780px)': {
                marginBottom: '60px',
              },
            }}
          >
            <img
              src={become_coach_image}
              alt='coach training a person'
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
      </BecomeCoachSectionContainer>
      <CoachTestimonialContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            alignItems: 'center',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
          }}
        >
          <TestimonialsText>Testimonials</TestimonialsText>
          <MeetCoachesText>{STRING_CONSTANTS.meet_happy_coaches}</MeetCoachesText>

          <TestimonialBox>
            <Box
              sx={{
                marginTop: '65px',
                marginBottom: '40px',
                '@media (max-width: 780px)': {
                  marginTop: '40px',
                  marginBottom: '24px',
                },
              }}
            >
              <img src={coach_avatar} alt='image of a coach at gymnies' />
            </Box>

            <CoachTestimonialText>
              <span style={{ fontStyle: 'italic', marginRight: '7px' }}>&quot;</span>
              {STRING_CONSTANTS.testimonial_text}
              <span style={{ fontStyle: 'italic' }}>&quot;</span>
            </CoachTestimonialText>

            <Box>
              <TestimonialPersonName>Amelia Williams</TestimonialPersonName>
              <TestimonialPersonDesignation>Coach at GYMNIES</TestimonialPersonDesignation>
            </Box>
          </TestimonialBox>
        </Box>
      </CoachTestimonialContainer>
      <AppPlatformSectionContainer sx={{ marginTop: '80px', paddingTop: '40px' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            justifyContent: {
              xs: 'center',
              sm: 'space-between',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            gap: '2rem',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
            '@media (max-width: 780px)': {
              gap: '24px',
              flexDirection: 'column-reverse',
            },
          }}
        >
          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <EachPlatformText>{STRING_CONSTANTS.available_on_platforms}</EachPlatformText>
            <BackedByExpertsText>{STRING_CONSTANTS.backed_by_experts_text}</BackedByExpertsText>

            <Box
              sx={{
                display: 'flex',
                gap: '30px',
                '@media (max-width: 425px)': {
                  justifyContent: 'space-between',
                },
              }}
            >
              <StoreButton variant='contained' disableRipple>
                <img src={apple_icon} alt='apple icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.apple_store}</StoreTypeText>
                </Box>
              </StoreButton>

              <StoreButton variant='contained' disableRipple>
                <img src={google_icon} alt='google icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.google_play}</StoreTypeText>
                </Box>
              </StoreButton>
            </Box>
          </CommonBox>

          <CommonBox
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <img
              src={mobile_app_preview}
              alt='gymnies mobile app preview'
              style={{ width: '100%', height: '100%' }}
            />
          </CommonBox>
        </Box>
      </AppPlatformSectionContainer>
      <ContactSectionContainer sx={{ overflow: 'hidden' }}>
        <Box
          sx={{
            width: '50%',
            '@media (max-width: 780px)': {
              display: 'none',
            },
          }}
        >
          <img
            src={contact_image}
            alt='contact gymnies image'
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <ContactFormContainer sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '65%',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '445px',

              '@media (max-width: 780px)': {
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <ContactText>{STRING_CONSTANTS.contact_us}</ContactText>
            <GetInTouchText>{STRING_CONSTANTS.get_in_touch}</GetInTouchText>

            <CustomInput
              label='Your name'
              textInputProps={{
                error: false,
                style: { width: '100%' },
              }}
            />

            <CustomInput
              label='Your email'
              textInputProps={{
                type: 'email',
                error: false,
                style: { width: '100%' },
              }}
            />

            <CustomTextareaInput label={'Message'} />

            <Box
              sx={{
                display: 'flex',
                gap: '30px',
                marginTop: '24px',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '425px',
                  alignItems: 'center',
                  gap: '20px',
                },
                '@media (min-width: 600px) and (max-width: 780px)': {
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '343px',
                  alignItems: 'center',
                  gap: '20px',
                },
              }}
            >
              <CustomButton
                label='Submit'
                btnColor={colors.radio_border_black}
                type='contained'
                customStyle={{
                  width: '100%',
                  height: '56px',
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: '-0.36px',
                  color: colors.white,
                }}
              />

              <LoveToHearText>{STRING_CONSTANTS.would_love_to_hear}</LoveToHearText>
            </Box>
          </Box>
        </ContactFormContainer>
      </ContactSectionContainer>
      <GymniesFooter />
    </>
  );
}

export default BecomeACoachPage;

const BecomeCoachSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '1119px',
  display: 'flex',
  backgroundColor: colors.slider_tooltip_text,
  paddingTop: '60px',
}));

const TestimonialBox = styled(Box)<BoxProps>(() => ({
  // minHeight: '467px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  border: `2px solid ${colors.light_gray_input}`,
  borderRadius: '32px',
}));

const CoachTestimonialContainer = styled(Box)<BoxProps>(() => ({
  // height: '811px',
  display: 'flex',
  backgroundColor: colors.white,
  // marginBottom: '80px',
}));

const AppPlatformSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '474px',
  // maxWidth: '1440px',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const ContactSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '776px',
  width: '100%',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const ContactFormContainer = styled(Box)<BoxProps>(() => ({
  width: '50%',
  height: '100%',
  '@media (max-width: 780px)': {
    width: '100%',
  },
}));

const CommonBox = styled(Box)<BoxProps>(() => ({
  width: '42%',
}));

const ButtonsWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '16px',
}));

const BecomeGymniesCoachText = styled(Typography)<TypographyProps>(() => ({
  width: '100%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  color: colors.header_text,
  textAlign: 'center',
  letterSpacing: '-1.5px',
  lineHeight: '56px',
  '@media (max-width: 329px)': {
    fontSize: '32px',
    lineHeight: '45px',
  },
  '@media (min-width: 330px) and (max-width: 600px)': {
    fontSize: '36px',
    lineHeight: '50px',
    marginBottom: 0,
  },
}));

const BecomeCoachDescText = styled(Typography)<TypographyProps>(() => ({
  width: '100%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  color: colors.dark_gray,
  textAlign: 'center',
  letterSpacing: '-1.5px',
  lineHeight: '32px',
  marginBottom: '32px',
  '@media (max-width: 600px)': {
    width: '90%',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.5px',
  },
}));

const ContactAndDownloadButton = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  gap: '12px',
  width: '160px',
  minWidth: '160px',
  borderRadius: '8px',
  backgroundColor: colors.theme_blue,
  height: '48px',
  padding: '0px 17px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: colors.theme_blue,
    boxShadow: 'none',
  },
}));

const ContactAndDownloadBtnText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: colors.slider_tooltip_text,
  textAlign: 'center',
  textTransform: 'capitalize',
  lineHeight: '16px',
}));

const TestimonialsText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: colors.header_text,
  opacity: 0.5,
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: '4px',
  lineHeight: '24px',
  marginTop: 54,
  marginBottom: 24,
  '@media (max-width: 600px)': {
    marginBottom: 16,
  },
}));

const ContactText = styled(TestimonialsText)<TypographyProps>(() => ({
  marginTop: 80,
  marginBottom: 32,
  textAlign: 'left',
}));

const MeetCoachesText = styled(BecomeGymniesCoachText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 80,
  '@media (max-width: 780px)': {
    fontSize: '36px',
    marginBottom: 32,
  },
}));

const EachPlatformText = styled(MeetCoachesText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 40,
  textAlign: 'left',
  '@media (max-width: 780px)': {
    fontSize: '36px',
    marginBottom: 16,
  },
  '@media (max-width: 1022px)': {
    fontSize: '36px',
  },
}));

const GetInTouchText = styled(EachPlatformText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 56,
  '@media (max-width: 500px)': {
    width: '95%',
    fontSize: '34px',
    textAlign: 'center',
  },
  '@media (min-width: 501px) and (max-width: 780px)': {
    width: '95%',
    fontSize: '36px',
  },
}));

const CoachTestimonialText = styled(Typography)<TypographyProps>(() => ({
  width: '50%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  color: colors.header_text,
  textAlign: 'center',
  letterSpacing: '-0.8px',
  lineHeight: '34px',
  marginBottom: '40px',
  '@media (max-width: 780px)': {
    width: '90%',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    marginBottom: '24px',
  },
}));

const TestimonialPersonName = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  color: colors.header_text,
  textAlign: 'center',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginBottom: '8px',
  '@media (max-width: 780px)': {
    fontSize: '16px',
    marginBottom: '4px',
  },
}));

const TestimonialPersonDesignation = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.paragraph_text,
  // opacity: 0.5,
  textAlign: 'center',
  letterSpacing: '-0.3px',
  lineHeight: '24px',
  marginBottom: '80px',
  '@media (max-width: 780px)': {
    fontSize: '12px',
    marginBottom: '40px',
  },
}));

const BackedByExpertsText = styled(Typography)<TypographyProps>(() => ({
  width: '110%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginBottom: 56,
  '@media (max-width: 780px)': {
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 32,
  },
}));

const StoreButton = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  gap: '12px',
  width: '100%',
  minWidth: '118px',
  maxWidth: '160px',
  borderRadius: '8px',
  backgroundColor: colors.header_text,
  height: '58px',
  padding: '0px 17px',
  '&:hover': {
    backgroundColor: colors.header_text,
  },
}));

const GetItOnText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'none',
  '@media (max-width: 1022px)': {
    fontSize: '11px',
  },
}));

const StoreTypeText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'capitalize',
  '@media (max-width: 1022px)': {
    fontSize: '12px',
  },
}));

const LoveToHearText = styled(Typography)<TypographyProps>(() => ({
  width: '25%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.3px',
  lineHeight: '24px',
  '@media (max-width: 780px)': {
    width: '100%',
    textAlign: 'center',
    marginBottom: '60px',
  },
}));
