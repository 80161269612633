import styled from '@emotion/styled';
import { Box, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
interface Props {
  coachData: any;
}

const AboutSection = ({ coachData }: Props) => {
  return (
    <Grid>
      <Section>
        <SectionSubLabel>Bio</SectionSubLabel>
        <SectionSubContent>{coachData?.about}</SectionSubContent>
      </Section>

      <Section>
        <SectionSubLabel>Speciality</SectionSubLabel>
        <SectionSubContent
          sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '6px' }}
        >
          {coachData?.coachDetails?.specialities?.map((spec: string) => (
            <Chip label={spec} key={spec} sx={{ textTransform: 'capitalize' }} />
          ))}
        </SectionSubContent>
      </Section>

      <Section>
        <SectionSubLabel>Language Known</SectionSubLabel>
        <SectionSubContent
          sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '6px' }}
        >
          {coachData?.coachDetails?.languages?.map((lang: string) => (
            <Chip label={lang} key={lang} sx={{ textTransform: 'capitalize' }} />
          ))}
        </SectionSubContent>
      </Section>

      <Section>
        <SectionSubLabel>Certification</SectionSubLabel>
        <SectionSubContent></SectionSubContent>
      </Section>
    </Grid>
  );
};

const Section = styled(Box)`
  padding: 10px;
`;

const SectionSubLabel = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #2d3142;
`;

const SectionSubContent = styled(Box)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4c5980;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export default AboutSection;
