import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import toast, { Toaster } from 'react-hot-toast';
import Logo from '../assets/gymnies_logo.svg';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { defaultCountry } from '../components/PhoneInput/PhoneInput';
import { auth } from '../firebaseConfig';
import PhoneInput from '../components/PhoneInput';
import { LoginRightContent } from '../components/LoginRightContent/LoginRightContent';
import CustomInput from '../components/CustomInput';
import { useAppDispatch } from '../store/hooks';
import { addUser } from '../store/Auth/AuthSlice';
import { modifyUserObject } from '../utils/helperFunctions';

export const LoginPage = () => {
  const [countryCode, setCountryCode] = useState<string | undefined>(defaultCountry.phone);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [OTP, setOTP] = useState<string>('');
  const [isVerifyOTP, setIsVerifyOTP] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onrecaptchaVerifier = () => {
    if (!(window as any).recaptchaVerifier) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {
            // console.log(response);
          },
          'expired-callback': () => {
            console.log('expired');
          },
        },
        auth,
      );
    }
  };

  const handleLogin = () => {
    if (phoneNumber.length < 10) {
      setError(true);
      return;
    }
    setIsBtnDisabled(true);
    onrecaptchaVerifier();
    const verifier = (window as any).recaptchaVerifier;
    const formattedPhoneNumber = `${countryCode}${phoneNumber}`;
    signInWithPhoneNumber(auth, formattedPhoneNumber, verifier).then((confirmationResult) => {
      (window as any).confirmationResult = confirmationResult;
      // console.log('inside signin handle', confirmationResult, formattedPhoneNumber);
      setIsVerifyOTP(true);
      toast.success('OTP sent successfully!');
      // setIsBtnDisabled(false);
    });
  };

  const handleOTPChange = (e: any) => {
    if (e.target.value?.length == 6) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
    setError(false);
    setOTP(e.target.value);
  };

  const handleOtpVerify = () => {
    setIsBtnDisabled(true);

    (window as any).confirmationResult
      .confirm(OTP)
      .then(async (res: any) => {
        setIsBtnDisabled(false);

        // console.log('after OTP confirm', res);
        const updatedUserObj = modifyUserObject(res);
        localStorage.setItem('user', JSON.stringify(updatedUserObj));
        dispatch(addUser(updatedUserObj));
        navigate('/');
      })
      .catch((err: any) => {
        console.log('inside confirm validation fail', err);
        setError(true);
        setIsBtnDisabled(false);
      });
  };

  const handlePhoneInputChange = (e: any) => {
    if (e.target.value.length > 10) return;
    if (error) setError(false);
    setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    (window as any).recaptchaVerifier = null;
  }, [isVerifyOTP]);

  const renderLeftContainer = () => {
    if (!isVerifyOTP) {
      return (
        <LoginContainer>
          <Title>Login</Title>
          <PhoneInput
            value={countryCode}
            onValueChange={(value: string | null) => {
              if (value) {
                setCountryCode(value);
              } else {
                setCountryCode('');
              }
            }}
            customStyle={{ maxWidth: '368px' }}
            handleInputChange={handlePhoneInputChange}
            inputError={error}
            mobileNumberValue={phoneNumber}
          />
          <CustomButton
            label={isBtnDisabled ? 'Loading...' : 'Login'}
            type='contained'
            btnColor='#192126'
            customStyle={{ maxWidth: '368px', height: '56px' }}
            onClickFunc={handleLogin}
            isDisabled={isBtnDisabled}
          />
        </LoginContainer>
      );
    } else {
      return (
        <LoginContainer>
          <Title>OTP</Title>
          <CustomInput
            label='Enter otp'
            customStyle={{ maxWidth: '368px' }}
            onInputChange={handleOTPChange}
            defaultValue={OTP}
            customInputProps={{ maxLength: 6 }}
            error={error}
            errorMessage={error ? 'invalid OTP' : ''}
          />
          <CustomButton
            label={'Submit'}
            type='contained'
            btnColor='#192126'
            customStyle={{ maxWidth: '368px', height: '56px' }}
            onClickFunc={handleOtpVerify}
            isDisabled={isBtnDisabled}
          />
          {/* <SecondaryText>
            {"Didn't get OTP?"} <span>Resend OTP </span>
          </SecondaryText> */}
        </LoginContainer>
      );
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <LeftGrid item>
        <img
          src={Logo}
          style={{ position: 'absolute', top: '6%', left: '8%', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        {renderLeftContainer()}
      </LeftGrid>

      <RightGrid item>
        <LoginRightContent />
      </RightGrid>

      <div id='recaptcha-container'></div>
      <Toaster />
    </Grid>
  );
};

export const LeftGrid = styled(Grid)`
  background: '#FFF';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
    display: grid;
    width: 100%;
  }
`;

export const LoginContainer = styled(Container)`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-left: 12%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const Title = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  color: #192126;
`;

export const SecondaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 400;
  line-height: 150%;
  & > span {
    text-decoration: underline;
    font-family: inherit;
  }
`;

export const RightGrid = styled(Grid)`
  background: linear-gradient(180deg, #efe9fb 0%, rgba(239, 233, 251, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    display: none;
  }
`;
