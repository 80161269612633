import React, { useState } from 'react';
import { Avatar, Grid, List, ListItem, Menu, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Logo from '../../assets/gymnies_logo.svg';
import HamburgerMenu from '../../assets/hamburger.svg';
import Sidebar from '../Sidebar/Sidebar';
import { AuthState, removeUser } from '../../store/Auth/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { auth } from '../../firebaseConfig';
import { AccountCircle } from '@mui/icons-material';

interface Props {
  isAuth?: boolean;
}

const Navbar = ({ isAuth = false }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenu, setIsMenu] = useState(false);
  const Auth = useAppSelector(AuthState);
  const user = localStorage.getItem('user');

  const dispatch = useAppDispatch();

  if (user) {
    isAuth = true;
  }

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleProfileClick = (e: any) => {
    setAnchorEl(e.target);
    setIsMenu(!isMenu);
  };
  return (
    <>
      <Grid
        sx={{
          position: 'fixed',
          width: '100vw',
          height: '105px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #E7E5EA;',
          backgroundColor: '#fff',
          zIndex: 999,
          padding: '0px 110px',
          justifyContent: 'space-between',
          '@media(max-width:768px)': {
            padding: '0px 16px',
          },
        }}
      >
        <List sx={{ display: 'flex', gap: '20px' }}>
          <StyledListItem>
            <Link to='/'>
              <img src={Logo} />
            </Link>
          </StyledListItem>
          <StyledListItem
            sx={{
              '@media(max-width:768px)': {
                display: 'none',
              },
            }}
          >
            <Link to='/about' style={{ color: '#12022f', textDecoration: 'none' }}>
              About
            </Link>
          </StyledListItem>
          <StyledListItem
            sx={{
              '@media(max-width:768px)': {
                display: 'none',
              },
            }}
          >
            <Link to='/become-a-coach' style={{ color: '#12022f', textDecoration: 'none' }}>
              Become a Coach
            </Link>
          </StyledListItem>
        </List>

        <List
          sx={{
            '@media(max-width:768px)': {
              display: 'none',
            },
          }}
        >
          {!isAuth ? (
            <StyledListItem>
              <Link to='/login' style={{ color: '#12022f', textDecoration: 'none' }}>
                Sign In
              </Link>
            </StyledListItem>
          ) : (
            <StyledListItem onClick={handleProfileClick}>
              {auth?.currentUser?.photoURL ? (
                <Avatar src={auth?.currentUser?.photoURL} sx={{ height: '55px', width: '55px' }} />
              ) : (
                <AccountCircle fontSize={'inherit'} sx={{ height: '55px', width: '55px' }} />
              )}
            </StyledListItem>
          )}
        </List>

        <MobileMenu>
          <img
            src={HamburgerMenu}
            style={{ width: '30px', height: '30px' }}
            onClick={handleSidebar}
          />
        </MobileMenu>
      </Grid>
      <Sidebar showSidebar={sidebarOpen} />
      {sidebarOpen && <OverlayGrid onClick={handleSidebar} />}
      <Menu open={isMenu} anchorEl={anchorEl} onClose={() => setIsMenu(!isMenu)}>
        <MenuItem
          onClick={() => {
            auth.signOut();
            dispatch(removeUser());
            setIsMenu(false);
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

const StyledListItem = styled(ListItem)`
  width: max-content;
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.355556px;
  color: #12022f;
  &:hover {
    cursor: pointer;
  }
`;

const MobileMenu = styled(List)`
  display: none;
  @media (max-width: 768px) {
    margin-right: 8px;
    display: flex;
    gap: 12px;
  }
`;

const OverlayGrid = styled(Grid)`
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.7;
  z-index: 999;
  position: absolute;
`;

export default Navbar;
