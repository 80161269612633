import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries, CountryType } from '../../utils/constants/countriesConstant';
import { colors } from '../../utils/constants/colors';
import { createTheme, ThemeProvider } from '@mui/material';
import CustomInput from '../CustomInput';

export const defaultCountry: CountryType = {
  code: 'US',
  label: 'United States',
  phone: '1',
  suggested: true,
};

const theme = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          minWidth: 100,
          width: '100%',
          border: '1px solid',
          borderColor: colors.input_border,
          backgroundColor: `${colors.input_bg} !important`,
          '&.Mui-error': {
            borderColor: colors.theme_red,
          },
        },
        input: {
          color: colors.input_text,
          backgroundColor: colors.input_bg,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          paddingBottom: 8,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.input_label,
          textTransform: 'uppercase',
          letterSpacing: 1,
          paddingLeft: 6,
          // * comment this to animate label on focus
          transform: 'translate(12px, 10px) scale(0.7)',
          '&.Mui-error': {
            color: colors.theme_red,
          },

          '&.Mui-focused': {
            color: colors.input_label,
            textTransform: 'uppercase',
            letterSpacing: 1,
            paddingLeft: 6,
            '&.Mui-error': {
              color: colors.theme_red,
            },
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          border: '1px solid',
          borderColor: colors.input_border,
          backgroundColor: colors.input_bg,
          '&::before': { display: 'none' },
          '&::after': { display: 'none' },
          '&.Mui-error': {
            borderColor: colors.theme_red,
          },
        },
        input: {
          backgroundColor: colors.input_bg,
        },
        endAdornment: {
          display: 'none',
        },
      },
    },
  },
});

// * prop types
type PhoneInputProps = {
  label?: string;
  value: string | undefined;
  onValueChange: (value: string | null) => void;
  customStyle?: Record<string, any>;
  handleInputChange?: (e: any) => void;
  inputError?: boolean;
  mobileNumberValue?:string;
};

/**
 *
 * Custom phone input
 * @param label string
 * @param value string | undefined
 * @param onValueChange (value: string | null) => void
 *
 * @returns Phone Input JSX
 */
function PhoneInput({
  label = 'Code',
  value,
  onValueChange,
  customStyle,
  handleInputChange,
  inputError,
  mobileNumberValue
}: PhoneInputProps) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', gap: '16px', ...customStyle }}>
        <Autocomplete
          id='country-select'
          disableClearable
          options={countries}
          blurOnSelect
          defaultValue={defaultCountry}
          inputValue={value}
          onInputChange={(event, newValue) => {
            onValueChange(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          autoHighlight
          openOnFocus
          getOptionLabel={(option) => `+${option.phone}`}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
              key={`${option.code}`}
            >
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=''
              />
              +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              margin='normal'
              label={label}
              variant={'filled'}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          ListboxProps={{
            style: {
              height: '100%',
              maxHeight: '250px',
            },
          }}
        />

        <CustomInput
          label='Mobile Number'
          textInputProps={{
            error: false,
            style: {
              width: '100%',
            },
          }}
          onInputChange={handleInputChange}
          customInputProps={{ maxLength: 10 }}
          error={inputError}
          errorMessage={inputError ? 'Enter valid mobile number!' : ''}
          value={mobileNumberValue}
        />
      </Box>
    </ThemeProvider>
  );
}

export default PhoneInput;
