import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Dropdown from '../Dropdown';
import CustomRating from '../CustomRating';
import CustomSlider from '../CustomSlider';
import CloseCircle from '../../assets/close_black.svg';
import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown';
import { useAppSelector } from '../../store/hooks';
import { FiltersState } from '../../store/Filters/FiltersSlice';

interface FilterMenuProps {
  handleDistanceFilterChange: (value: number) => void;
  handlePriceFilterChange: (value: number) => void;
  handleGenderFilterChange: (value: string) => void;
  handleSpecialityFilterChange: (value: Array<Record<string, any>>) => void;
  handleLanguageFilterChange: (value: string) => void;
  handleApplyFilterOnMobile?: () => void;
  handleResetFilters?: () => void;
  genderValue?: string;
  specialityValue?: Array<Record<string, any>>;
  languageValue?: string;
  priceSliderValue?: number;
  distanceSliderValue?: number;
}

function FilterMenu({
  handlePriceFilterChange,
  handleDistanceFilterChange,
  handleGenderFilterChange,
  handleSpecialityFilterChange,
  handleLanguageFilterChange,
  handleApplyFilterOnMobile,
  handleResetFilters,
  genderValue,
  specialityValue,
  languageValue,
  priceSliderValue,
  distanceSliderValue,
}: FilterMenuProps) {
  const filtersState = useAppSelector(FiltersState);

  return (
    <>
      {/* TODO : needs to be integrated once data finalised */}
      {/* <FilterBox>
        <DropdownTitle>Services</DropdownTitle>
        <Dropdown
          list={[
            { id: 1, label: 'test' },
            { id: 2, label: 'test2' },
          ]}
          value={'test'}
          onValueChange={(value) => {
            if (value) return;
          }}
        />
      </FilterBox> */}

      <FilterBox>
        <DropdownTitle>Gender</DropdownTitle>
        <Dropdown
          list={[
            { id: 1, label: 'do not wish to  mention' },
            { id: 2, label: 'male' },
            { id: 3, label: 'female' },
          ]}
          value={filtersState.genderValue}
          placeholderValue={'Select Gender'}
          onValueChange={handleGenderFilterChange}
        />
      </FilterBox>

      {/* TODO : needs to be integrated once data finalised */}
      {/* <FilterBox>
        <DropdownTitle>Rating</DropdownTitle>
        <CustomRating />
      </FilterBox> */}

      {/* <FilterBox>
        <DropdownTitle>Price range</DropdownTitle>
        <CustomSlider
          type={'price'}
          minValue={10}
          defaultValue={priceSliderValue}
          handleFilterChange={handlePriceFilterChange}
        />
      </FilterBox> */}

      <FilterBox>
        <DropdownTitle>Miles range</DropdownTitle>
        <CustomSlider
          type={'distance'}
          defaultValue={filtersState.distanceSliderValue}
          minValue={5}
          maxValue={100}
          handleFilterChange={handleDistanceFilterChange}
        />
      </FilterBox>

      <Divider
        sx={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      />

      {/* TODO : needs to be integrated once data finalised */}
      {/* <FilterBox>
        <DropdownTitle>Language</DropdownTitle>
        <Dropdown
          list={[
            { id: 1, label: 'English' },
            { id: 2, label: 'French' },
            { id: 3, label: 'Spanish' },
          ]}
          value={languageValue}
          placeholderValue={'Select Language'}
          onValueChange={handleLanguageFilterChange}
        />
      </FilterBox> */}

      <FilterBox>
        <DropdownTitle>Speciality</DropdownTitle>
        <MultiSelectDropdown
          list={[
            { id: 1, label: 'nutritionist' },
            { id: 2, label: 'strength training' },
            { id: 3, label: 'yoga' },
            { id: 4, label: 'life coaching' },
            { id: 5, label: 'mind' },
          ]}
          value={filtersState.specialityValue}
          onValueChange={handleSpecialityFilterChange}
        />
      </FilterBox>

      <Divider
        sx={{
          display: 'none',
          '@media (max-width: 768px)': {
            display: 'block',
            margin: '8px 0px',
          },
        }}
      />

      <FilterActionsGrid>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <img src={CloseCircle} alt='close' />
          <Typography
            sx={{
              fontFamily: 'DM Sans',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '16px',
              color: '#23262F',
              cursor: 'pointer',
            }}
            onClick={handleResetFilters}
          >
            Reset filters
          </Typography>
        </Box>

        <Typography
          sx={{
            fontFamily: 'DM Sans',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '16px',
            color: '#3772FF',
            cursor: 'pointer',
          }}
          onClick={handleApplyFilterOnMobile}
        >
          Apply
        </Typography>
      </FilterActionsGrid>
    </>
  );
}

export default FilterMenu;

const FilterBox = styled(Box)`
  @media (max-width: 768px) {
    width: 100%;
    // padding: 10px 10%;
    padding: 10px 0px;
  }
`;

const FilterActionsGrid = styled(Grid)`
  // display: none;
  // @media (max-width: 768px) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  margin: 6px 0px;
  // }
`;

const DropdownTitle = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #12022f;
`;
