import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import {
  BoxProps,
  Button,
  ButtonProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  Typography,
  TypographyProps,
  useRadioGroup,
} from '@mui/material';
import { colors } from '../utils/constants/colors';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import jumping_rope from '../assets/jumping_rope.svg';
import meditate from '../assets/meditate.svg';
import life_coaching from '../assets/life_coaching.svg';
import radio_button_checked from '../assets/radio_button_checked.svg';
import radio_button_unchecked from '../assets/radio_button_unchecked.svg';
import homepage_purple_bg from '../assets/homepage_purple_bg.svg';
import homepage_girl_fitness from '../assets/homepage_girl_fitness.svg';
import welcome_gymnies_bg from '../assets/welcome_gymnies_bg.svg';
import apple_icon from '../assets/apple_icon.svg';
import google_icon from '../assets/google_icon.svg';
import mail_icon from '../assets/mail.svg';
import mobile_app_preview from '../assets/mobile_app_preview.svg';
import CustomInput from '../components/CustomInput';
import { CustomButton } from '../components/CustomButton/CustomButton';
import GymniesFooter from '../components/GymniesFooter';
import Navbar from '../components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { UserDetailsModal } from '../components/UserDetailsModal/UserDetailsModal';
import { useAppSelector } from '../store/hooks';
import { AuthState } from '../store/Auth/AuthSlice';
import { getUserFromFirebase } from '../utils/helperFunctions';
import { updateProfile } from 'firebase/auth';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ checked }) => ({
  fontFamily: 'DM Sans',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between !important',
  letterSpacing: '-0.35px',

  '.MuiFormControlLabel-label': checked && {
    color: colors.input_text,
    fontWeight: '700',
  },
  '@media (max-height: 668px)': {
    '.MuiTypography-root': {
      fontSize: '14px',
    },
  },
}));

function FitnessFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;
  let labelIcon = '';

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  switch (props.label) {
    case 'Health and Fitness':
      labelIcon = jumping_rope;
      break;
    case 'Therapy and Counselling':
      labelIcon = meditate;
      break;
    case 'Life Coaching':
      labelIcon = life_coaching;
      break;
  }

  return (
    <FitnessServiceRadioBox
      sx={{
        borderColor: checked ? colors.radio_border_black : colors.input_border,
      }}
    >
      <img src={labelIcon} alt='' />
      <StyledFormControlLabel checked={checked} {...props} sx={{ width: '100%' }} />
    </FitnessServiceRadioBox>
  );
}

function HomePage() {
  const navigate = useNavigate();
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const authState = useAppSelector(AuthState);

  const [selectedFitnessService, setSelectedFitnessService] = useState('Health and Fitness');

  const handleSelectFitnessService = (event: any, value: string) => {
    setSelectedFitnessService(value);
  };

  useEffect(() => {
    const userData = getUserFromFirebase(authState.user?.uid).then((res) => {
      if (auth.currentUser) {
        const updatedUserProfile = updateProfile(auth.currentUser, {
          displayName: res?.fullname,
          photoURL: res?.profilePictureUrl,
        })
          .then(() => {
            // Profile updated!
            // ...
            console.log('profile details updated');
          })
          .catch((error) => {
            // An error occurred
            // ...
            console.log(error);
          });
      }
      if (!res) {
        setIsNewUser(true);
      }
    });
  }, [auth.currentUser]);

  const handleCoachPage = () => {
    navigate('/coach-listing');
  };

  return (
    <>
      <Navbar />

      <HeroSectionContainer
        sx={{
          marginLeft: {
            xs: '0%',
            sm: '10%',
            md: '10%',
            lg: '10%',
            xl: '25%',
          },
          justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'space-between',
            lg: 'space-between',
            xl: 'space-around',
          },
          paddingTop: '160px',
          gap: '20px',
          '@media (max-width: 600px)': {
            paddingTop: '120px',
            margin: '0 1rem',
          },
        }}
      >
        <MainLeftBox>
          <Box
            sx={{
              '@media (max-width: 400px)': {
                display: 'flex',
                flexWrap: 'wrap',
                gap: '6px',
                fontSize: '36px',
              },
            }}
          >
            <TitleText variant='h1'>{`Find your local\n`}</TitleText>
            <TitleText
              variant='h1'
              sx={{ color: colors.theme_red }}
              display={'inline'}
            >{`FITNESS `}</TitleText>
            <TitleText variant='h1' display={'inline'}>{`Trainer`}</TitleText>
          </Box>

          <FitnessDescriptionText
            variant='subtitle1'
            sx={{
              // width: {
              //   xs: 343,
              //   sm: 343,
              //   md: 445,
              //   lg: 445,
              //   xl: 445,
              // },
              width: '100%',
              maxWidth: 445,
            }}
          >
            {STRING_CONSTANTS.fitness_description}
          </FitnessDescriptionText>

          <RadioGroup
            name='fitness-service'
            defaultValue='Health and Fitness'
            value={selectedFitnessService}
            onChange={handleSelectFitnessService}
            sx={{
              // width: {
              //   xs: 343,
              //   sm: 343,
              //   md: 420,
              //   lg: 420,
              //   xl: 420,
              // },
              width: '100%',
              maxWidth: 420,
            }}
          >
            <FitnessFormControlLabel
              value='Health and Fitness'
              label='Health and Fitness'
              control={
                <Radio
                  checkedIcon={<img src={radio_button_checked} />}
                  icon={<img src={radio_button_unchecked} />}
                  disableRipple
                />
              }
              labelPlacement={'start'}
            />
            <FitnessFormControlLabel
              value='Therapy and Counselling'
              label='Therapy and Counselling'
              control={
                <Radio
                  checkedIcon={<img src={radio_button_checked} />}
                  icon={<img src={radio_button_unchecked} />}
                  disableRipple
                />
              }
              labelPlacement={'start'}
            />
            <FitnessFormControlLabel
              value='Life Coaching'
              label='Life Coaching'
              control={
                <Radio
                  checkedIcon={<img src={radio_button_checked} />}
                  icon={<img src={radio_button_unchecked} />}
                  disableRipple
                />
              }
              labelPlacement={'start'}
            />
          </RadioGroup>

          <Box
            sx={{
              // width: {
              //   xs: 343,
              //   sm: 343,
              //   md: 420,
              //   lg: 420,
              //   xl: 420,
              // },
              width: '100%',
              maxWidth: 420,
              marginBottom: '24px',
              '@media (max-height: 668px)': {
                marginBottom: '14px',
              },
            }}
          >
            <CustomInput
              label='Enter Zip Code'
              textInputProps={{
                error: false,
                style: { width: '100%', marginTop: '7px' },
              }}
            />
          </Box>

          <Box
            sx={{
              width: '160px',
              height: '56px',
              '@media (max-width: 600px)': {
                width: '100%',
                maxWidth: 420,
              },
            }}
          >
            <CustomButton
              label='Search'
              btnColor={colors.radio_border_black}
              type='contained'
              customStyle={{
                width: '100%',
                height: '100%',
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: '-0.36px',
              }}
              onClickFunc={handleCoachPage}
            />
          </Box>
        </MainLeftBox>

        <MainRightBox sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, bottom: '0px', zIndex: 2 }}>
            <img
              src={homepage_purple_bg}
              alt='Fitness bg'
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              zIndex: 3,
              right: 0,
              bottom: '100px',
              '@media (max-width: 1300px)': {
                right: 0,
              },
              '@media (min-width: 1300px) and (max-width: 1440px)': {
                right: '10rem',
              },
              '@media (min-width: 1441px) and (max-width: 2560px)': {
                right: '10rem',
              },
            }}
          >
            <img
              src={homepage_girl_fitness}
              alt='Fitness girl'
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </MainRightBox>
      </HeroSectionContainer>

      <WelcomeSectionContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '10%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            gap: '20px',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
            '@media (max-width: 780px)': {
              flexDirection: 'column',
              paddingTop: '60px',
            },
          }}
        >
          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <img
              src={welcome_gymnies_bg}
              alt='Fitness people'
              style={{
                width: '100%',
                height: '100%',
                maxWidth: 540,
              }}
            />
          </CommonBox>

          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
                paddingBottom: '60px',
              },
            }}
          >
            <IgniteText>{STRING_CONSTANTS.ignite_your_energy}</IgniteText>
            <LifeWithGymniesText>{STRING_CONSTANTS.better_with_gymnies}</LifeWithGymniesText>
            <WelcomeToGymniesText>{STRING_CONSTANTS.welcome_to_gymnies_text}</WelcomeToGymniesText>

            <Box
              sx={{
                width: '255px',
                height: '56px',
                '@media (max-width: 600px)': {
                  width: '100%',
                  maxWidth: 420,
                },
              }}
            >
              <CustomButton
                label='Download App'
                btnColor={colors.radio_border_black}
                type='contained'
                customStyle={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: '-0.36px',
                  color: colors.white,
                }}
              />
            </Box>
          </CommonBox>
        </Box>
      </WelcomeSectionContainer>

      <AppPlatformSectionContainer sx={{ marginTop: '40px' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            justifyContent: {
              xs: 'center',
              sm: 'space-between',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            gap: '2rem',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
            '@media (max-width: 780px)': {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <EachPlatformText>{STRING_CONSTANTS.available_on_platforms}</EachPlatformText>
            <ByExpertsText>{STRING_CONSTANTS.backed_by_experts_text}</ByExpertsText>

            <Box
              sx={{
                display: 'flex',
                gap: '30px',
                '@media (max-width: 425px)': {
                  justifyContent: 'space-between',
                },
              }}
            >
              <StoreButton variant='contained' disableRipple>
                <img src={apple_icon} alt='apple icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.apple_store}</StoreTypeText>
                </Box>
              </StoreButton>

              <StoreButton variant='contained' disableRipple>
                <img src={google_icon} alt='google icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.google_play}</StoreTypeText>
                </Box>
              </StoreButton>
            </Box>
          </CommonBox>

          <CommonBox
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <img
              src={mobile_app_preview}
              alt='gymnies mobile app preview'
              style={{ width: '100%', height: '100%' }}
            />
          </CommonBox>
        </Box>
      </AppPlatformSectionContainer>

      <ConnectSectionContainer>
        <StayConnectedText>{STRING_CONSTANTS.stay_connected_with_us}</StayConnectedText>
        <GetUpdateText>{STRING_CONSTANTS.get_update_text}</GetUpdateText>

        <Box
          sx={{
            display: 'flex',
            gap: '30px',
            alignItems: 'center',
            paddingBottom: '80px',
            '@media (max-width: 500px)': {
              flexDirection: 'column',
              width: '100%',
              paddingBottom: '0',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              '@media (min-width: 768px)': {
                width: '360px',
              },
            }}
          >
            <CustomInput
              label=''
              textInputProps={{
                error: false,
                style: { width: '100%', margin: 0 },
                placeholder: 'Type your email',
              }}
              inputLabelProps={{}}
              customStyle={{
                // '.MuiInputLabel-root': {
                //   paddingLeft: '30px',
                // },
                '.MuiFilledInput-input': {
                  paddingTop: '15px',
                  paddingBottom: '17px',
                  paddingLeft: '44px',
                },
              }}
            />
            <img src={mail_icon} style={{ position: 'absolute', left: 12, top: 16 }} />
          </Box>

          <Box
            sx={{
              width: '160px',
              height: '56px',
              '@media (max-width: 500px)': {
                width: '100%',
                marginBottom: '60px',
              },
            }}
          >
            <CustomButton
              label='Subscribe'
              btnColor={colors.radio_border_black}
              type='contained'
              customStyle={{
                width: '100%',
                height: '100%',
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: '-0.36px',
                color: colors.white,
              }}
            />
          </Box>
        </Box>
      </ConnectSectionContainer>

      <UserDetailsModal
        isModalOpen={isNewUser}
        handleModalAction={() => {
          setIsNewUser(!isNewUser);
        }}
      />

      <GymniesFooter />
    </>
  );
}

export default HomePage;

const HeroSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '660px',
  // marginRight: '10%',
  maxWidth: '1440px',
  // paddingTop: '40px',
  display: 'flex',
  marginBottom: 60,
  backgroundColor: colors.white,
  '@media (max-width: 600px)': {
    paddingBottom: '60px',
  },
}));

const WelcomeSectionContainer = styled(Box)<BoxProps>(() => ({
  minHeight: '700px',
  // maxWidth: '1440px',
  display: 'flex',
  backgroundColor: colors.light_blue_bg,
  alignItems: 'center',
}));

const AppPlatformSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '474px',
  // maxWidth: '1440px',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const ConnectSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '355px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.light_blue_bg,
  alignItems: 'center',
  padding: '0 16px',
  '@media (max-width: 500px)': {
    marginTop: '60px',
  },
}));

const CommonBox = styled(Box)<BoxProps>(() => ({
  width: '42%',
}));

const MainLeftBox = styled(Box)<BoxProps>(() => ({
  width: '42%',
  '@media (max-width: 780px)': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
}));

const MainRightBox = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '54%',
  '@media (max-width: 780px)': {
    display: 'none',
  },
}));

const TitleText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-2px',
  lineHeight: '55px',
  // marginBottom: 17,
  '@media (max-width: 329px)': {
    fontSize: '32px',
  },
  '@media (min-width: 330px) and (max-width: 600px)': {
    fontSize: '36px',
    lineHeight: '50px',
    marginBottom: 0,
  },
}));

const FitnessDescriptionText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginTop: 25,
  marginBottom: 29,
  '@media (max-width: 600px)': {
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: 8,
    marginBottom: 27,
  },
}));

const FitnessServiceRadioBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  border: '1px solid',
  borderRadius: '8px',
  padding: '12px 16px',
  marginBottom: '12px',
  '@media (max-height: 668px)': {
    padding: '6px 12px',
  },
}));

const IgniteText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: colors.header_text,
  opacity: 0.5,
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: '4px',
  lineHeight: '24px',
  marginTop: 54,
  marginBottom: 24,
  '@media (max-width: 600px)': {
    fontSize: '12px',
    marginTop: 4,
    marginBottom: 16,
  },
}));

const LifeWithGymniesText = styled(Typography)<TypographyProps>(() => ({
  width: '80%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-2px',
  lineHeight: '56px',
  marginBottom: 30,
  '@media (max-width: 329px)': {
    fontSize: '32px',
  },
  '@media (min-width: 330px) and (max-width: 600px)': {
    fontSize: '36px',
    lineHeight: '50px',
    marginBottom: 24,
  },
}));

const WelcomeToGymniesText = styled(Typography)<TypographyProps>(() => ({
  // width: '80%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginBottom: 50,
  '@media (max-width: 600px)': {
    fontSize: '14px',
    lineHeight: '21px',
    marginBottom: 24,
  },
}));

const EachPlatformText = styled(LifeWithGymniesText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 40,
  '@media (max-width: 780px)': {
    fontSize: '36px',
    marginBottom: 16,
  },
}));

const ByExpertsText = styled(WelcomeToGymniesText)<TypographyProps>(() => ({
  width: '110%',
  marginBottom: 56,
  '@media (max-width: 780px)': {
    width: '100%',
    marginBottom: 32,
  },
}));

const StayConnectedText = styled(LifeWithGymniesText)<TypographyProps>(() => ({
  width: '100%',
  textAlign: 'center',
  marginTop: 80,
  marginBottom: 20,
  '@media (max-width: 780px)': {
    width: '75%',
    lineHeight: '50px',
    fontSize: '36px',
    marginBottom: 16,
    marginTop: 60,
  },
}));

const GetUpdateText = styled(WelcomeToGymniesText)<TypographyProps>(() => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: 36,
}));

const StoreButton = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  gap: '12px',
  width: '100%',
  minWidth: '118px',
  maxWidth: '160px',
  borderRadius: '8px',
  backgroundColor: colors.header_text,
  height: '58px',
  padding: '0px 17px',
  '&:hover': {
    backgroundColor: colors.header_text,
  },
}));

const GetItOnText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'none',
  '@media (max-width: 1022px)': {
    fontSize: '11px',
  },
}));

const StoreTypeText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'capitalize',
  '@media (max-width: 1022px)': {
    fontSize: '12px',
  },
}));
