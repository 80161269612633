import React, { Fragment, useState } from 'react';
import { Box, Typography, BoxProps, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { colors } from '../../utils/constants/colors';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

type CustomPdfViewerProps = {
  pdfFile: any;
};

type DocumentLoadedProps = {
  numPages: number;
};

const options = {
  // cMapUrl: 'cmaps/',
  // cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

function CustomPdfViewer({ pdfFile }: CustomPdfViewerProps) {
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages, ...other }: DocumentLoadedProps) {
    setNumPages(nextNumPages);
  }

  return (
    <PdfWrapper>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {Array.from(new Array(numPages), (el, index) => (
          <Fragment key={`page_${index + 1}`}>
            <Page pageNumber={index + 1} />
            <PageNumberText>Page {index + 1}</PageNumberText>
          </Fragment>
        ))}
      </Document>
    </PdfWrapper>
  );
}

export default CustomPdfViewer;

const PdfWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  backgroundColor: colors.slider_tooltip_text,
  justifyContent: 'center',
  paddingTop: '105px',
}));

const PageNumberText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.paragraph_text,
  textAlign: 'center',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginTop: 25,
  marginBottom: 29,
  '@media (max-width: 600px)': {
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: 20,
    marginBottom: 24,
  },
}));
