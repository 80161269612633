import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import CustomPdfViewer from '../components/CustomPdfViewer';
import terms_and_conditions_of_use from '../static/terms_and_conditions_of_use.pdf';
import GymniesFooter from '../components/GymniesFooter';

function TermsAndConditionsPage() {
  return (
    <>
      <Navbar />
      <CustomPdfViewer pdfFile={terms_and_conditions_of_use} />
      <GymniesFooter />
    </>
  );
}

export default TermsAndConditionsPage;
