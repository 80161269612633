import React from 'react';
import { Grid, Box, MenuItem, Stack, Typography, Avatar } from '@mui/material';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/gymnies_logo.svg';
import { auth } from '../../firebaseConfig';
import { signOut } from 'firebase/auth';
import { useAppDispatch } from '../../store/hooks';
import { removeUser } from '../../store/Auth/AuthSlice';
import { AccountCircle } from '@mui/icons-material';

interface props {
  showSidebar: boolean;
}

const Sidebar = ({ showSidebar }: props) => {
  const dispatch = useAppDispatch();

  const user = localStorage.getItem('user');
  let isAuth = false;
  if (user) {
    isAuth = true;
  }

  const handleLogout = () => {
    signOut(auth)
      .then((res) => {
        // console.log(res);
        dispatch(removeUser());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();
  return (
    <SidebarWrapper sx={showSidebar ? { display: 'block' } : { display: 'none' }}>
      <Box sx={{ padding: '20px 10px', marginBottom: '6px' }}>
        <Link to='/'>
          <img src={Logo} />
        </Link>
      </Box>
      {isAuth && (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            background: '#F0E2E2',
            padding: '10px 5px',
          }}
        >
          {auth?.currentUser?.photoURL ? (
            <Avatar src={auth?.currentUser?.photoURL} sx={{ height: '40px', width: '40px' }} />
          ) : (
            <AccountCircle fontSize={'large'} />
          )}
          <Typography>{auth?.currentUser?.displayName}</Typography>
        </Box>
      )}
      <Stack spacing={1}>
        {!isAuth && (
          <MenuItem onClick={() => navigate('/login')}>
            <MenuText>Sign In</MenuText>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate('/about')}>
          <MenuText>About</MenuText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/become-a-coach')}>
          <MenuText>Become a coach</MenuText>
        </MenuItem>
        {isAuth && (
          <MenuItem onClick={handleLogout}>
            <MenuText>Logout</MenuText>
          </MenuItem>
        )}
      </Stack>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled(Grid)`
  position: fixed;
  height: 100vh;
  width: 250px;
  background: #fff;
  z-index: 99999;
  padding: 10px;
`;

const MenuText = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.355556px;
`;

export default Sidebar;
