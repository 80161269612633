import React, { useState } from 'react';
import { Autocomplete, createTheme, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import { colors } from '../../utils/constants/colors';
import dropdown_arrow_down from '../../assets/dropdown_arrow_down.svg';

type DropdownProps = {
  label?: string;
  list: Array<{ id: string | number; label: string }>;
  value: string | undefined;
  onValueChange: (value: string) => void;
  placeholderValue?: string;
};

function Dropdown({ label = '', list, value, onValueChange, placeholderValue }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        id='select_dropdown'
        options={list}
        sx={{ minWidth: 256 }}
        open={isOpen}
        blurOnSelect
        // defaultValue={list[0]}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        // inputValue={value}
        value={{ id: 0, label: value ? value : '' }}
        onInputChange={(event, newValue) => {
          onValueChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholderValue ? placeholderValue : 'Select'}
            label={label}
            onClick={() => {
              if (isOpen) {
                setIsOpen(false);
              } else {
                setIsOpen(true);
              }
            }}
            onBlur={() => setIsOpen(false)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  <img src={dropdown_arrow_down} style={{}} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </ThemeProvider>
  );
}

export default Dropdown;

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          minWidth: 100,
          width: '100%',
          borderColor: colors.dropdown_border,
          paddingRight: '8px !important',
          paddingLeft: '16px !important',
          // backgroundColor: `${colors.input_bg} !important`,
          // '&.Mui-error': {
          //   borderColor: colors.dropdown_border,
          // },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid !important',
            borderColor: `${colors.dropdown_border} !important`,
          },
        },

        input: {
          color: colors.paragraph_text,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          paddingBottom: 8,
          textTransform: 'capitalize',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.dropdown_border,
          textTransform: 'uppercase',
          letterSpacing: 1,
          paddingLeft: 6,
          // * comment this to animate label on focus
          transform: 'translate(12px, 10px) scale(0.7)',
          '&.Mui-error': {
            color: colors.theme_red,
          },

          '&.Mui-focused': {
            color: colors.dropdown_border,
            textTransform: 'uppercase',
            letterSpacing: 1,
            paddingLeft: 6,
            '&.Mui-error': {
              color: colors.theme_red,
            },
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '@media (max-width: 768px)': {
            width: '100%',
            padding: '10px 10%',
          },
          '&::before': { display: 'none !important' },
          '&::after': { display: 'none !important' },
          '&.Mui-error': {
            borderColor: colors.theme_red,
          },
        },
        input: {},
        endAdornment: {
          display: 'none',
        },
        option: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});
