import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import dropdown_arrow_down from '../../assets/dropdown_arrow_down.svg';
import './MultiSelectDropdown.css';

interface Props {
  label?: string;
  list: Array<{ id: string | number; label: string }>;
  value: Array<Record<string, any>> | undefined;
  onValueChange: (value: Array<Record<string, any>>) => void;
}

const MultiSelectDropdown = ({ list, onValueChange, value }: Props) => {
  const [selected, setSelected] = useState([]);
  const onSelect = (value: any) => {
    setSelected(value);
  };
  const onRemove = (value: any) => {
    setSelected(value);
  };

  useEffect(() => {
    onValueChange(selected);
  }, [selected.length]);
  return (
    <>
      <Multiselect
        style={{
          multiselectContainer: {
            //     // To change css for multiselect (Width,height,etc..)
            minHeight: '50px',
            // border: '#E5E6EB',
          },
          searchBox: {
            // To change search box element look
            // border: 'none',
            fontSize: '10px',
            minHeight: '50px',
          },
          inputField: {
            // To change input field position or margin
            minHeight: '40px',
          },
          chips: {
            // To change css chips(Selected options)
            background: '#EbEbEB',
            color: '#000',
            opacity: '0.7',
            textTransform: 'capitalize',
          },
          closeIcon: {
            background: '#000',
          },
          option: {
            textTransform: 'capitalize',
          },
        }}
        options={list} // Options to display in the dropdown
        selectedValues={value} // Preselected value to persist in dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onRemove} // Function will trigger on remove event
        displayValue='label' // Property name to display in the dropdown options
        showCheckbox={true}
        closeIcon={'cancel'}
        showArrow={true}
        customArrow={<img src={dropdown_arrow_down} style={{}} />}
        placeholder={'Select Speciality'}
      />
    </>
  );
};

export default MultiSelectDropdown;
