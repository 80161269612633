import React, { useEffect } from 'react';
import { BoxProps, Button, ButtonProps, styled, Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from '../utils/constants/colors';
import jogging_girl from '../assets/jogging_girl.svg';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import { CustomButton } from '../components/CustomButton/CustomButton';
import apple_icon from '../assets/apple_icon.svg';
import google_icon from '../assets/google_icon.svg';
import gymnies_app_images from '../assets/gymnies_app_images.svg';
// import gymnies_app_images_mobile from '../assets/gymnies_app_images_mobile.svg';
import tick_icon from '../assets/tick_icon.svg';
import people_avatar from '../assets/people_avatar.svg';
import person_avatar_2 from '../assets/person_avatar_2.svg';
import person_avatar_3 from '../assets/person_avatar_3.svg';
import contact_image from '../assets/contact_image.svg';
import GymniesFooter from '../components/GymniesFooter';
import CustomInput from '../components/CustomInput';
import { CustomTextareaInput } from '../components/CustomInput/CustomInput';
import Navbar from '../components/Navbar/Navbar';
import { useLocation } from 'react-router-dom';

const featureList = [
  'Find local Trainers',
  'Discover local Workshops',
  'Join the Fitness Community',
  'Share Recipes',
  'Share Workouts',
];

function AboutPage() {
  // useEffect(() => {
  //   window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);
  const location = useLocation();

  useEffect(() => {
    // console.log('in location', location);
    if (location?.state?.targetId) {
      const el = document.getElementById(location?.state?.targetId);
      el?.scrollIntoView();
    }
  }, [location.state?.targetId]);

  return (
    <>
      <Navbar />
      <AboutUsSectionContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            gap: '20px',
            paddingTop: '120px',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
            '@media (max-width: 780px)': {
              flexDirection: 'column',
            },
          }}
        >
          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <img src={jogging_girl} alt='jogging girl' style={{ width: '100%', height: '100%' }} />
          </CommonBox>

          <CommonBox
            sx={{
              width: '44%',
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <AboutUsText>{STRING_CONSTANTS.about_us}</AboutUsText>
            <Box
              sx={{
                marginBottom: '40px',
                '@media (max-width: 500px)': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '6px',
                  fontSize: '36px',
                  marginBottom: '24px',
                },
              }}
            >
              <TrainSmarterText
                variant='h1'
                display={'inline'}
              >{`Train Smarter Get `}</TrainSmarterText>
              <TrainSmarterText variant='h1' display={'inline'}>{`Stronger with`}</TrainSmarterText>
              <TrainSmarterText
                variant='h1'
                sx={{ color: colors.theme_red }}
                display={'inline'}
              >{` GYMNIES`}</TrainSmarterText>
            </Box>

            <AboutUsDescriptionText>{STRING_CONSTANTS.about_us_description}</AboutUsDescriptionText>

            <Box
              sx={{
                width: '255px',
                height: '56px',
                '@media (max-width: 600px)': {
                  width: '100%',
                  maxWidth: '425px',
                },
                '@media (min-width: 600px) and (max-width: 780px)': {
                  width: '100%',
                  maxWidth: '343px',
                },
              }}
            >
              <CustomButton
                label='Explore'
                btnColor={colors.radio_border_black}
                type='contained'
                customStyle={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: '-0.36px',
                  color: colors.white,
                }}
              />
            </Box>
          </CommonBox>
        </Box>
      </AboutUsSectionContainer>

      <OurAppSectionContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginLeft: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            marginRight: {
              xs: '0%',
              sm: '10%',
              md: '10%',
              lg: '10%',
              xl: '25%',
            },
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'space-between',
              lg: 'space-between',
              xl: 'space-between',
            },
            gap: '20px',
            '@media (max-width: 600px)': {
              margin: '0 1rem',
            },
            '@media (max-width: 780px)': {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <CommonBox
            sx={{
              '@media (max-width: 780px)': {
                width: '100%',
                marginBottom: '60px',
              },
            }}
          >
            <OurAppText>{STRING_CONSTANTS.our_application}</OurAppText>

            <AllNewGymniesText>{STRING_CONSTANTS.meet_new_gymnies}</AllNewGymniesText>
            <KickstartDescriptionText>
              {STRING_CONSTANTS.download_app_desc_text}
            </KickstartDescriptionText>

            <Box sx={{ marginBottom: '48px' }}>
              {featureList &&
                featureList.map((feature, index) => (
                  <FeatureItemContainer key={`feature_name_${index}`}>
                    <img src={tick_icon} alt='tick icon' />
                    <AppFeatureText>{feature}</AppFeatureText>
                  </FeatureItemContainer>
                ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '30px',
                '@media (max-width: 425px)': {
                  justifyContent: 'space-between',
                },
              }}
            >
              <StoreButton variant='contained' disableRipple>
                <img src={apple_icon} alt='apple icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.apple_store}</StoreTypeText>
                </Box>
              </StoreButton>

              <StoreButton variant='contained' disableRipple>
                <img src={google_icon} alt='google icon' />
                <Box>
                  <GetItOnText>{STRING_CONSTANTS.get_it_on_text}</GetItOnText>
                  <StoreTypeText>{STRING_CONSTANTS.google_play}</StoreTypeText>
                </Box>
              </StoreButton>
            </Box>
          </CommonBox>

          <CommonBox
            sx={{
              width: '52%',
              paddingTop: '90px',
              '@media (max-width: 600px)': {
                paddingTop: '40px',
              },
              '@media (max-width: 780px)': {
                width: '100%',
              },
            }}
          >
            <img
              src={gymnies_app_images}
              alt='gymnies mobile app images'
              style={{
                // paddingTop: '90px',
                width: '100%',
                height: '100%',
              }}
            />
          </CommonBox>
        </Box>
      </OurAppSectionContainer>

      <TestimonialSectionContainer>
        <PeopleImagesWrapper
          sx={{
            paddingTop: '80px',
            paddingBottom: '90px',
            '@media (max-width: 780px)': {
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '60px',
              paddingBottom: '32px',
            },
          }}
        >
          <img src={person_avatar_2} alt='Testimonial Person' />
          <img src={people_avatar} alt='Testimonial Person' />
          <img src={person_avatar_3} alt='Testimonial Person' />
        </PeopleImagesWrapper>

        <TestimonialText>
          <span style={{ fontStyle: 'italic', marginRight: '7px' }}>&quot;</span>
          {STRING_CONSTANTS.testimonial_text}
          <span style={{ fontStyle: 'italic' }}>&quot;</span>
        </TestimonialText>

        <Box
          sx={{
            marginBottom: '80px',
            '@media (max-width: 500px)': {
              marginBottom: '60px',
            },
          }}
        >
          <TestimonialPersonName>Stephen B</TestimonialPersonName>
          <TestimonialPersonDesignation>Designation</TestimonialPersonDesignation>
        </Box>
      </TestimonialSectionContainer>

      <ContactSectionContainer sx={{ overflow: 'hidden' }}>
        <Box
          sx={{
            width: '50%',
            '@media (max-width: 780px)': {
              display: 'none',
            },
          }}
        >
          <img
            src={contact_image}
            alt='contact gymnies image'
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <ContactFormContainer
          sx={{ display: 'flex', justifyContent: 'center' }}
          id={'contact-form'}
        >
          <Box
            sx={{
              width: '65%',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '445px',

              '@media (max-width: 780px)': {
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <ContactText>{STRING_CONSTANTS.contact_us}</ContactText>
            <GetInTouchText>{STRING_CONSTANTS.get_in_touch}</GetInTouchText>

            <CustomInput
              label='Your name'
              textInputProps={{
                error: false,
                style: { width: '100%' },
              }}
            />

            <CustomInput
              label='Your email'
              textInputProps={{
                type: 'email',
                error: false,
                style: { width: '100%' },
              }}
            />

            <CustomTextareaInput label={'Message'} />

            <Box
              sx={{
                display: 'flex',
                gap: '30px',
                marginTop: '24px',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '425px',
                  alignItems: 'center',
                  gap: '20px',
                },
                '@media (min-width: 600px) and (max-width: 780px)': {
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '343px',
                  alignItems: 'center',
                  gap: '20px',
                },
              }}
            >
              <CustomButton
                label='Submit'
                btnColor={colors.radio_border_black}
                type='contained'
                customStyle={{
                  width: '100%',
                  // maxWidth: '255px',
                  height: '56px',
                  fontSize: 16,
                  fontWeight: 700,
                  letterSpacing: '-0.36px',
                  color: colors.white,
                }}
              />

              <LoveToHearText>{STRING_CONSTANTS.would_love_to_hear}</LoveToHearText>
            </Box>
          </Box>
        </ContactFormContainer>
      </ContactSectionContainer>
      <GymniesFooter />
    </>
  );
}

export default AboutPage;

const AboutUsSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '700px',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const OurAppSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '858px',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const TestimonialSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '617px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.testimonial_orange,
  alignItems: 'center',
  justifyContent: 'space-evenly',
}));

const ContactSectionContainer = styled(Box)<BoxProps>(() => ({
  // height: '776px',
  width: '100%',
  display: 'flex',
  backgroundColor: colors.white,
  alignItems: 'center',
}));

const PeopleImagesWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '40px',
  alignItems: 'center',
}));

const CommonBox = styled(Box)<BoxProps>(() => ({
  width: '42%',
}));

const ContactFormContainer = styled(Box)<BoxProps>(() => ({
  width: '50%',
  height: '100%',
  '@media (max-width: 600px)': {
    paddingTop: '60px',
  },
  '@media (max-width: 780px)': {
    width: '100%',
    marginBottom: '60px',
  },
}));

const AboutUsText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: colors.header_text,
  opacity: 0.5,
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: '4px',
  lineHeight: '24px',
  marginTop: 54,
  marginBottom: 24,
  '@media (max-width: 600px)': {
    marginTop: 0,
    marginBottom: 16,
  },
}));

const TrainSmarterText = styled(Typography)<TypographyProps>(() => ({
  width: '100%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-2px',
  lineHeight: '56px',
  '@media (max-width: 329px)': {
    fontSize: '32px',
    lineHeight: '45px',
  },
  '@media (min-width: 330px) and (max-width: 600px)': {
    fontSize: '36px',
    lineHeight: '50px',
    marginBottom: 0,
  },
}));

const AboutUsDescriptionText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  lineHeight: '28px',
  marginBottom: 50,
  '@media (max-width: 600px)': {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 24,
  },
}));

const OurAppText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: colors.header_text,
  opacity: 0.5,
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: '4px',
  lineHeight: '24px',
  marginTop: 54,
  marginBottom: 24,
  '@media (max-width: 600px)': {
    marginTop: 0,
    marginBottom: 16,
  },
}));

const ContactText = styled(OurAppText)<TypographyProps>(() => ({
  marginTop: 80,
  marginBottom: 32,
}));

const AllNewGymniesText = styled(TrainSmarterText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 40,
  '@media (max-width: 500px)': {
    width: '90%',
    lineHeight: '50px',
    fontSize: '36px',
    marginBottom: 24,
  },
  '@media (min-width: 501px) and (max-width: 780px)': {
    width: '75%',
    lineHeight: '50px',
    fontSize: '36px',
    marginBottom: 24,
  },
}));

const GetInTouchText = styled(AllNewGymniesText)<TypographyProps>(() => ({
  width: '100%',
  marginBottom: 56,
  '@media (max-width: 500px)': {
    width: '95%',
    fontSize: '36px',
    textAlign: 'center',
  },
  '@media (min-width: 501px) and (max-width: 780px)': {
    width: '95%',
    fontSize: '36px',
  },
}));

const KickstartDescriptionText = styled(AboutUsDescriptionText)<TypographyProps>(() => ({
  width: '110%',
  marginBottom: 56,
  '@media (max-width: 780px)': {
    width: '90%',
    marginBottom: 32,
  },
}));

const StoreButton = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  gap: '12px',
  width: '100%',
  minWidth: '118px',
  maxWidth: '160px',
  borderRadius: '8px',
  backgroundColor: colors.header_text,
  height: '58px',
  padding: '0px 17px',
  '&:hover': {
    backgroundColor: colors.header_text,
  },
}));

const GetItOnText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'none',
  '@media (max-width: 1022px)': {
    fontSize: '11px',
  },
}));

const StoreTypeText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: colors.white,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  textTransform: 'capitalize',
  '@media (max-width: 1022px)': {
    fontSize: '12px',
  },
}));

const FeatureItemContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '29px',
  alignItems: 'center',
  marginBottom: 24,
}));

const AppFeatureText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '22px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-0.4px',
  lineHeight: '32px',
  '@media (max-width: 780px)': {
    fontSize: '18px',
  },
}));

const TestimonialText = styled(Typography)<TypographyProps>(() => ({
  width: '50%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  color: colors.white,
  textAlign: 'center',
  letterSpacing: '-1.6px',
  lineHeight: '46px',
  marginBottom: '70px',
  '@media (max-width: 780px)': {
    width: '90%',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '32px',
  },
}));

const TestimonialPersonName = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '22px',
  color: colors.white,
  textAlign: 'center',
  letterSpacing: '-0.4px',
  lineHeight: '32px',
  marginBottom: '5px',
}));

const TestimonialPersonDesignation = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.white,
  opacity: 0.5,
  textAlign: 'center',
  letterSpacing: '-0.3px',
  lineHeight: '24px',
}));

const LoveToHearText = styled(Typography)<TypographyProps>(() => ({
  width: '25%',
  fontFamily: 'DM Sans',
  whiteSpace: 'pre-line',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.3px',
  lineHeight: '24px',
  '@media (max-width: 780px)': {
    width: '100%',
    textAlign: 'center',
  },
}));
