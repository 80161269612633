import React, { useState } from 'react';
import { Avatar, Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ShowMoreText from 'react-show-more-text';
import { CustomButton } from '../CustomButton/CustomButton';
import coach_avatar from '../../assets/coach_avatar.svg';
import message_icon from '../../assets/message_icon.svg';
import chat_icon from '../../assets/chat_icon.svg';
import { colors } from '../../utils/constants/colors';
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';

interface Props {
  coachData: any;
}

function CoachCard({ coachData }: Props) {
  const [seeMore, setSeeMore] = useState(false);
  const handleSeeMore = (expanded: boolean) => {
    setSeeMore(!expanded);
  };

  return (
    <>
      <CoachCardContainer>
        <Box>
          <Avatar
            src={coachData?.profilePictureUrl && coachData?.profilePictureUrl}
            // alt={`${coachData?.fullname}`}
            style={{ width: 160, height: 160, borderRadius: 80 }}
          />

          <CoachNameText sx={{ marginTop: '24px' }}>{coachData?.fullname}</CoachNameText>
        </Box>
        <CoachText>Coach</CoachText>

        <CoachInfoWrapper>
          <ShowMoreText
            lines={3}
            more='Show more'
            less='Show less'
            className='see-more-text'
            anchorClass='see-more-expand-text'
            onClick={handleSeeMore}
            expanded={seeMore}
            width={200}
            truncatedEndingComponent={'... '}
          >
            {/* Hi there, I’m Strength and yoga specialist & I’m extremely happy that I could bring{' '}
            {STRING_CONSTANTS.about_us_description}
            {STRING_CONSTANTS.about_us_description}
            {STRING_CONSTANTS.about_us_description}
            {STRING_CONSTANTS.about_us_description} */}
            {coachData?.about}
          </ShowMoreText>
        </CoachInfoWrapper>

        <FollowText>Follow +</FollowText>
        <CoachCTAWrapper>
          <CustomButton
            label='Book PT Session'
            btnColor={colors.theme_blue}
            type='contained'
            customStyle={{
              width: '100%',
              height: '40px',
              fontSize: 16,
              fontWeight: 700,
              letterSpacing: '-0.36px',
              color: colors.white,
            }}
          />
          <CustomIconButton color='default' aria-label='message coach' disableRipple>
            <img src={message_icon} alt='message coach button' />
          </CustomIconButton>
          <CustomIconButton color='default' aria-label='chat with coach' disableRipple>
            <img src={chat_icon} alt='chat with coach button' />
          </CustomIconButton>
        </CoachCTAWrapper>

        <CoachStatsWrapper>
          <StatWrapper
            sx={{
              borderRight: `1px solid ${colors.coach_card_line}`,
              paddingRight: '11px',
            }}
          >
            <StatText>4.8 Star</StatText>
            <StatTypeText>Rating</StatTypeText>
          </StatWrapper>

          <StatWrapper>
            <StatText>120k</StatText>
            <StatTypeText>Followers</StatTypeText>
          </StatWrapper>

          <StatWrapper
            sx={{
              borderLeft: `1px solid ${colors.coach_card_line}`,
              paddingLeft: '13px',
            }}
          >
            <StatText>230</StatText>
            <StatTypeText>Following</StatTypeText>
          </StatWrapper>
        </CoachStatsWrapper>
      </CoachCardContainer>
    </>
  );
}

export default CoachCard;

const CoachCardContainer = styled(Box)<BoxProps>(() => ({
  minHeight: '598px',
  width: '350px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `32px 24px`,
  backgroundColor: colors.slider_tooltip_text,
  border: `1px solid #E6E8EC`,
  boxShadow: `0px 40px 32px -24px rgba(15, 15, 15, 0.12)`,
  borderRadius: '16px',
  '@media (min-width: 1024px) and (max-width: 1200px)': {
    width: '300px',
    height: '500px',
  },
}));

const CoachNameText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  color: colors.download_app_text,
  textAlign: 'center',
  marginBottom: '4px',
  letterSpacing: '-0.4px',
  textTransform: 'capitalize',
}));

const CoachText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  color: colors.theme_red,
  textAlign: 'center',
  marginBottom: '20px',
  letterSpacing: '-0.36px',
  textTransform: 'uppercase',
}));

const CoachInfoWrapper = styled(Box)<BoxProps>(() => ({
  maxHeight: 200,
  overflowY: 'auto',
  marginBottom: 20,
}));

const FollowText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  color: colors.theme_blue,
  textAlign: 'center',
  marginBottom: '28px',
  letterSpacing: '-0.3px',
  cursor: 'default',
}));

const CoachCTAWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: '8px',
  marginBottom: '28px',
}));

const CustomIconButton = styled(IconButton)<IconButtonProps>(() => ({
  border: `1px solid ${colors.radio_border_black}`,
  borderRadius: '8px',
}));

const CoachStatsWrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  borderRadius: '8px',
  backgroundColor: '#E7E5EA80',
  padding: '12px 17px',
}));

const StatWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const StatText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  color: colors.header_text,
  textAlign: 'left',
  letterSpacing: '-0.3px',
}));

const StatTypeText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: colors.paragraph_text,
  textAlign: 'left',
  letterSpacing: '-0.3px',
}));
