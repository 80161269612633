export const STRING_CONSTANTS = {
  see_more: 'See more',
  see_less: 'See less',
  fitness_description:
    'Gymnies helps you find top-notch local fitness trainers tailored to your goals.',
  ignite_your_energy: 'Ignite your energy',
  better_with_gymnies: 'Life’s better with GYMNIES',
  welcome_to_gymnies_text:
    'Welcome to GYMNIES – a powerful new platform dedicated to reach your health and wellness goals. Join our community by downloading the Gymnies App.',
  available_on_platforms: 'Available Now on Each Platform!',
  backed_by_experts_text:
    'Backed by experts, powered by people like you. GYMNIES strives Health and Fitness to be accessible to all.',
  get_it_on_text: 'Get it on',
  apple_store: 'Apple Store',
  google_play: 'Google Play',
  stay_connected_with_us: 'Stay Connected with us',
  get_update_text: 'Get update and exciting offers from us.',
  copyright_text: '2023 gymnies. All rights reserved.',
  about_us: 'About us',
  about_us_description: `Whether you're looking to lose weight, build muscle, improve endurance, or enhance your overall fitness level, our platform connects you with certified trainers who are passionate about helping you succeed. Take the first step towards a healthier lifestyle by discovering your ideal fitness trainer today.`,
  our_application: 'Our Applications',
  meet_new_gymnies: 'Meet the All-New GYMNIES',
  download_app_desc_text:
    'Download the Gymnies app and join our mission in building the largest fitness community.',
  testimonial_text:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'.",
  contact_us: 'Contact Us',
  get_in_touch: 'Get in Touch with Us with Any Questions',
  would_love_to_hear: `We'd love to hear             from you!`,
  meet_happy_coaches: 'Meet Our Happy Coaches',
};

export enum SUBCRIPTION_PLAN_TYPE{
  quarterly = "12 Weeks",
  semiAnnual = "24 Weeks",
  annual = "52 Weeks"
}
