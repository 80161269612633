import React from 'react';
import { Button } from '@mui/material';
import styled from '@emotion/styled';

type variantType = 'text' | 'contained' | 'outlined' | undefined;

interface Props {
  label: string;
  type: variantType;
  btnColor?: string;
  labelColor?: string;
  customStyle?: Record<string, unknown>;
  icon?: any;
  onClickFunc?: () => any;
  isDisabled?: boolean;
}

export const CustomButton = ({
  label,
  btnColor,
  labelColor,
  type,
  customStyle,
  icon,
  onClickFunc,
  isDisabled,
}: Props) => {
  return (
    <StyledBtn
      variant={type}
      sx={{
        ...{ borderRadius: '8px' },
        ...(type == 'contained' ? { background: btnColor && btnColor } : {}),
        ...(type == 'outlined' ? { borderColor: btnColor } : { border: 0 }),
        color: type == 'contained' ? labelColor : btnColor,
        ...customStyle,
        '&:hover': {
          ...(type == 'contained'
            ? { background: btnColor, opacity: 0.9 }
            : { background: '#fff', borderColor: btnColor, ...customStyle }),
        },
        '&:disabled': {
          opacity: 0.8,
          background: btnColor,
          color: '#fff',
        },
      }}
      endIcon={icon ? icon : ''}
      onClick={() => {
        onClickFunc && onClickFunc();
      }}
      disabled={isDisabled}
    >
      {label}
    </StyledBtn>
  );
};

const StyledBtn = styled(Button)`
  min-height: 40px;
  font-family: DM sans;
  text-transform: capitalize;
`;
