import React from 'react';
import { Avatar, Card, Grid, ListItem, Typography, Divider } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import styled from '@emotion/styled';
import { CustomButton } from '../CustomButton/CustomButton';
import CoachListingBadge from '../../assets/coach_listing_badge.svg';
import IconMiles from '../../assets/icon_miles.svg';
import FilledStar from '../../assets/filled_star.svg';
import UnFilledStar from '../../assets/unfilled_star.svg';
import ArrowRight from '../../assets/arrow-right.svg';
import Location from '../../assets/location.svg';
import Users_3 from '../../assets/users_3.svg';
import { getCoachDistanceFromUser } from '../../utils/helperFunctions';

interface Props {
  data?: Record<string, any>;
  customStyles?: Record<string, unknown>;
  currentUserLocation?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CoachListingCard = ({ data, currentUserLocation }: Props) => {
  let miles: string | number = 0;

  if (data?.position?.geopoint && currentUserLocation) {
    miles = getCoachDistanceFromUser(data?.position?.geopoint, [
      currentUserLocation?.lat,
      currentUserLocation?.long,
    ]);
  }

  return (
    <StyledCard>
      <Grid style={{ margin: '20px 24px', display: 'flex' }}>
        <Avatar
          src={data?.profilePictureUrl && data?.profilePictureUrl}
          sx={{ height: '55px', width: '55px' }}
        />
        <Container>
          <StyledCardTitle variant='h1'>{data?.fullname}</StyledCardTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <img src={FilledStar} style={{ height: '15px', width: '15px' }} />
              <img src={UnFilledStar} style={{ height: '15px', width: '15px' }} />
            </Box>
            <Typography>(51)</Typography>
          </Box>
        </Container>
      </Grid>

      <Divider sx={{ margin: '0px 24px' }} />

      <Grid
        sx={{
          margin: '24px 24px 12px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack>
          <StyledListItem sx={{ alignItems: 'flex-start' }}>
            <img src={Location} style={{ marginTop: '4px' }} />
            <SubText
              sx={{
                width: '80%',
                '@media(max-width:500px)': {
                  // width: '50%',
                  width: '120px',
                  height: '50px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'break-spaces',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                },
              }}
            >
              {data?.coachDetails?.address}
            </SubText>
          </StyledListItem>
          <StyledListItem>
            <img src={Users_3} />
            <SubText>client count</SubText>
          </StyledListItem>
        </Stack>
        <CustomButton
          label={'starts from $45'}
          type='outlined'
          btnColor='#12022F'
          customStyle={{
            // width: '200px',
            // height: 'inherit',
            padding: '10px 24px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '-0.3px',
            borderWidth: '2px',
            maxHeight: '60px',
            width: '50%',
            '@media(max-width:500px)': {
              padding: '5px 12px',
              height: '50px',
              width: '60%',
            },
          }}
          icon={<img src={ArrowRight} />}
        />
      </Grid>

      <div style={{ position: 'absolute', top: 0, right: '20px' }}>
        <img
          src={CoachListingBadge}
          style={{ position: 'absolute', top: '0%', right: 0, height: '70px', width: '60px' }}
        />
        <Typography
          sx={{
            color: 'white',
            position: 'absolute',
            top: '8px',
            right: 0,
            fontSize: '12px',
            fontWeight: 700,
            width: '60px',
            textAlign: 'center',
          }}
        >
          {`${miles} m`}
        </Typography>
        <img
          src={IconMiles}
          style={{
            position: 'absolute',
            top: '24px',
            right: '17px',
            height: '40px',
            width: '25px',
          }}
        />
      </div>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  background: #ffffff;
  border: 1px solid #e5e6eb;
  border-radius: 8px;
  box-shadow: none;
  position: relative;
  min-height: 180px;
`;

const StyledCardTitle = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #192126;
  text-transform: capitalize;
`;

const StyledListItem = styled(ListItem)`
  padding: 0px;
  display: flex;
  gap: 10px;
`;

const SubText = styled(Typography)`
  font-family: 'work sans';
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
