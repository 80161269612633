import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import RouterWrapper from './components/RouterWrapper/RouterWrapper';
import AboutPage from './pages/AboutPage';
import BecomeACoachPage from './pages/BecomeACoachPage';
import CoachListingPage from './pages/CoachListingPage';
import CoachProfilePage from './pages/CoachProfilePage';
import HomePage from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { AuthState, removeUser, setUserInLocalStorage } from './store/Auth/AuthSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import AnonymousSessionWrapper from './components/AnonymousSessionWrapper/AnonymousSessionWrapper';
import { IsUserTokenExpired } from './utils/helperFunctions';

function App() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(AuthState);
  const user = localStorage.getItem('user');

  if (user && authState.user == null) {
    console.log('Is token expired', IsUserTokenExpired(JSON.parse(user)));
    if (IsUserTokenExpired(JSON.parse(user))) {
      dispatch(removeUser());
    } else {
      dispatch(setUserInLocalStorage(JSON.parse(user)));
    }
  }

  // console.log('user', authState);

  return (
    <div>
      <BrowserRouter>
        <RouterWrapper>
          <Routes>
            <Route
              path='/'
              element={
                <AnonymousSessionWrapper user={user}>
                  <HomePage />
                </AnonymousSessionWrapper>
              }
            />
            <Route path='/login' element={!user ? <LoginPage /> : <Navigate to={'/'} />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/become-a-coach' element={<BecomeACoachPage />} />
            <Route
              path='/coach-listing'
              element={
                <AnonymousSessionWrapper user={user}>
                  <CoachListingPage />
                </AnonymousSessionWrapper>
              }
            />
            <Route
              path='/coach-profile/:id'
              element={
                <AnonymousSessionWrapper user={user}>
                  <CoachProfilePage />
                </AnonymousSessionWrapper>
              }
            />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
          </Routes>
        </RouterWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
