import React from 'react';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { CourseCard } from '../CourseCard/CourseCard';

const CoursesSection = () => {
  return (
    <Grid item container gap={5} sx={{ padding: '8px' }}>
      <CourseWrapper sm={5.5}>
        <CourseCard />
      </CourseWrapper>
      <CourseWrapper sm={5.5}>
        <CourseCard />
      </CourseWrapper>
      <CourseWrapper sm={5.5}>
        <CourseCard />
      </CourseWrapper>
      <CourseWrapper sm={5.5}>
        <CourseCard />
      </CourseWrapper>
    </Grid>
  );
};

const CourseWrapper = styled(Grid)`
  width: 100%;
`;

export default CoursesSection;
