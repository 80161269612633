import React, { useState } from 'react';
import { Box, BoxProps } from '@mui/system';
import { styled } from '@mui/material/styles';
import { colors } from '../../utils/constants/colors';
import { Avatar, Container, Grid, Stack, Switch, Typography, TypographyProps } from '@mui/material';
import { STRING_CONSTANTS, SUBCRIPTION_PLAN_TYPE } from '../../utils/constants/stringConstants';
import circle_check from '../../assets/circle_check.svg';
import { CustomButton } from '../CustomButton/CustomButton';
import ArrowRightWhite from '../../assets/arrow_right_white.svg';
import { updateCoachServiceDetails } from '../../utils/helperFunctions';

type ServiceCardProps = {
  detailArray: Array<string>;
  serviceType?: string;
  serviceDetails?: any;
  isCoachView?: boolean;
  coachId?: string;
};

function ServiceCard({
  detailArray,
  serviceType,
  serviceDetails,
  isCoachView,
  coachId,
}: ServiceCardProps) {
  const [seeMore, setSeeMore] = useState(false);
  const cardDetailArray = seeMore ? detailArray : detailArray?.slice(0, 3);

  const handleSeeMore = () => {
    setSeeMore((value) => !value);
  };

  const handleServiceActiveStatusChange = (event: any, serviceId: string) => {
    updateCoachServiceDetails(coachId, serviceId, {
      isActive: event.target.checked,
      updatedOn: new Date(),
    });
  };

  return (
    <ServiceBox
      sx={{
        textAlign: 'left',
      }}
    >
      <HeaderText variant='h4'>
        {serviceDetails?.serviceName} -{' '}
        {
          SUBCRIPTION_PLAN_TYPE[
            serviceDetails?.subscriptionDuration as keyof typeof SUBCRIPTION_PLAN_TYPE
          ]
        }
      </HeaderText>
      <Box>
        {cardDetailArray?.map((detail, index) => (
          <Box key={`service_detail_${index}`} sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <img src={circle_check} style={{ marginRight: 16, marginTop: 3 }} />
            <DetailText>{detail}</DetailText>
          </Box>
        ))}
      </Box>

      {detailArray?.length > 3 ? (
        <Box
          component={'span'}
          sx={{
            paddingTop: '3px',
            paddingBottom: '3px',
            marginLeft: '40px',
          }}
          onClick={handleSeeMore}
        >
          <SeeMoreText variant='h6'>
            {seeMore ? STRING_CONSTANTS.see_less : STRING_CONSTANTS.see_more}
          </SeeMoreText>
        </Box>
      ) : (
        <></>
      )}

      <Stack sx={{ padding: '4px', marginTop: '30px' }} spacing='15px'>
        <StyledContainer>
          <StyledBox>
            <AvatarWrapper>
              <CustomAvatar
                src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                sx={{ height: '28px', width: '28px' }}
              />
              <CustomAvatar
                src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                sx={{ height: '28px', width: '28px', marginLeft: '-5px' }}
              />
              <CustomAvatar
                src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
                sx={{ height: '28px', width: '28px', marginLeft: '-5px' }}
              />
            </AvatarWrapper>
            <SecondaryText>230 enrolled</SecondaryText>
          </StyledBox>
          <Box sx={{ marginTop: '-2px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PriceText>${serviceDetails?.sellingPrice}</PriceText>
              {serviceType ? <ServiceTypeText>{serviceType}</ServiceTypeText> : <></>}
            </Box>
            <OriginalPriceText>${serviceDetails?.discountedPrice}</OriginalPriceText>
          </Box>
        </StyledContainer>
        {isCoachView ? (
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Typography
              sx={{
                color: !serviceDetails?.isActive ? '#ed2737' : '#00b717',
                fontWeight: 700,
                fontSize: '14px',
                textTransform: 'capitalize',
              }}
            >
              {`Service ${serviceDetails?.isActive ? 'Enabled' : 'Disabled'} `}
            </Typography>
            <Switch
              checked={serviceDetails?.isActive}
              onChange={(e) => handleServiceActiveStatusChange(e, serviceDetails.docId)}
            />
          </Grid>
        ) : (
          <CustomButton
            label={'Enrol'}
            type='contained'
            btnColor='#192126'
            customStyle={{
              width: '100%',
              padding: '10px 24px',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '-0.2px',
            }}
            icon={<img src={ArrowRightWhite} />}
          />
        )}
      </Stack>
    </ServiceBox>
  );
}

export default ServiceCard;

const ServiceBox = styled(Box)<BoxProps>(({ theme }) => ({
  // minWidth: 320,
  // minHeight: 418,
  backgroundColor: colors.white,
  border: `1px solid ${colors.input_border}`,
  padding: 16,
  borderRadius: 8,
  width: '100%',
}));

const HeaderText = styled(Typography)<TypographyProps>(() => ({
  margin: 0,
  width: '85%',
  textAlign: 'left',
  fontFamily: 'DM Sans',
  fontSize: 18,
  fontWeight: 700,
  color: colors.header_text,
  lineHeight: '28px',
  marginBottom: '13px',
  textTransform: 'capitalize',
}));

const DetailText = styled(Typography)<TypographyProps>(() => ({
  margin: 0,
  marginRight: 10,
  width: '90%',
  textAlign: 'left',
  fontFamily: 'DM Sans',
  fontSize: 14,
  fontWeight: 400,
  color: colors.paragraph_text,
  lineHeight: '24px',
  marginBottom: '16px',
  letterSpacing: '-0.2px',
  textTransform: 'capitalize',
}));

const SeeMoreText = styled(Typography)<TypographyProps>(() => ({
  margin: 0,
  display: 'inline',
  width: '100%',
  textAlign: 'left',
  fontFamily: 'DM Sans',
  fontSize: 14,
  fontWeight: 700,
  color: colors.theme_blue,
  lineHeight: '24px',
  marginBottom: '13px',
  cursor: 'pointer',
}));

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  @media (min-width: 600px) {
    padding: 0px;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  gap: 14px;
  align-items: center;
`;

const SecondaryText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const PriceText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  letter-spacing: -0.4px;
  margin-right: 6px;
`;

const ServiceTypeText = styled(Typography)`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

const OriginalPriceText = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  textDecorationLine: 'line-through',
  color: colors.original_price_purple,
}));

const AvatarWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  // backgroundColor: 'blue',
}));

const CustomAvatar = styled(Avatar)<BoxProps>(() => ({
  border: '2px solid',
  borderColor: colors.white,
}));
