import React from 'react';
import { Avatar, Box, BoxProps } from '@mui/material';
import LocationOn from '@mui/icons-material/LocationOn';
import { styled } from '@mui/material/styles';
import { colors } from '../../utils/constants/colors';
import CoachMapTooltip from './CoachMapTooltip';

export const K_SIZE = 56;
const K_WIDTH = 56;
const K_HEIGHT = 56;

export const MarkerPin = (props: any) => (
  <Box>
    <LocationOn
      sx={{
        color: colors.theme_red,
        fontSize: '40px',
        transform: 'translateZ(0) translate(-50%, -50%)',
        backfaceVisibility: 'hidden',
      }}
    />
  </Box>
);

function CoachMarker(props: any) {
  const markerTooltip = props?.$hover;

  return (
    <MarkerWrapper>
      {markerTooltip && <CoachMapTooltip {...props} />}
      <Avatar
        src={props?.image}
        sx={{ height: '56px', width: '56px', border: `2px solid ${colors.white}` }}
      />
    </MarkerWrapper>
  );
}

export default CoachMarker;

const MarkerWrapper = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
}));
