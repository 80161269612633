import React, { useState } from 'react';
import { Container, Modal, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import CustomInput from '../CustomInput';
// import { defaultCountry } from '../PhoneInput/PhoneInput';
import { CustomButton } from '../CustomButton/CustomButton';
import { addNewUserToFirebase } from '../../utils/helperFunctions';

interface Props {
  isModalOpen: boolean;
  handleModalAction: () => void;
}

export const UserDetailsModal = ({ isModalOpen, handleModalAction }: Props) => {
  // const [countryCode, setCountryCode] = useState<string | undefined>(defaultCountry.phone);
  const [userDetails, setUserDetails] = useState({
    username: '',
    fullname: '',
    email: '',
    instagram: '',
  });

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleDetailsSubmit = () => {
    if (userDetails.username == '' || userDetails.fullname == '') {
      setError(true);
      setErrorMsg('This field is required to be filled!');
    } else {
      addNewUserToFirebase(userDetails);
      setUserDetails({
        username: '',
        fullname: '',
        email: '',
        instagram: '',
      });
      handleModalAction();
    }
  };

  return (
    <Modal
      open={isModalOpen}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClose={handleModalAction}
    >
      <ModalContainer>
        <Title>Enter Your Details</Title>
        <CustomInput
          label='Username'
          textInputProps={{
            error: false,
            style: { width: '100%' },
          }}
          onInputChange={(e) => setUserDetails({ ...userDetails, username: e.target.value })}
          error={userDetails.username == '' && error}
          errorMessage={userDetails.username == '' ? errorMsg : ''}
        />

        <CustomInput
          label='Fullname'
          textInputProps={{
            error: false,
            style: { width: '100%' },
          }}
          onInputChange={(e) => setUserDetails({ ...userDetails, fullname: e.target.value })}
          error={userDetails.fullname == '' && error}
          errorMessage={userDetails.fullname == '' ? errorMsg : ''}
        />

        {/* <PhoneInput
          value={countryCode}
          onValueChange={(value: string | null) => {
            if (value) {
              setCountryCode(value);
            } else {
              setCountryCode('');
            }
          }}
        /> */}

        <CustomInput
          label='Email'
          textInputProps={{
            type: 'email',
            error: false,
            style: { width: '100%' },
          }}
          onInputChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
        />

        <CustomInput
          label='Instagram Link'
          textInputProps={{
            error: false,
            style: { width: '100%' },
          }}
          onInputChange={(e) => setUserDetails({ ...userDetails, instagram: e.target.value })}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            label='Submit'
            type='contained'
            btnColor='#192126'
            customStyle={{ minWidth: '300px', height: '50px', margin: '8px' }}
            onClickFunc={handleDetailsSubmit}
          />
        </Box>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(Container)`
  background: #fff;
  min-height: 360px;
  width: 400px;
  border-radius: 8px;
  border: none;
  padding: 16px;
`;

const Title = styled(Typography)`
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.8px;
  color: #12022f;
  text-align: center;
`;
